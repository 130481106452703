import React, {useContext, useEffect, useState} from 'react';
import "./Dashboard.css"
import {useNavigate} from 'react-router-dom';
import {Autocomplete, TextField} from "@mui/material";
import {auth, objDetail} from "../references/link";
import logoPath from "../ressources/images/logo + carboparc.png";
import {LanguageContext} from "../LanguageContext";
import img_favoris from "../ressources/images/favoris.svg"
import img_non_favoris from "../ressources/images/non_favoris.svg"
import LoaderIcon from "../ressources/tools/LoaderIcon";
import {FaPowerOff} from "react-icons/fa";
import {MdLocationOff} from "react-icons/md";


function Dashboard({data, defaultLang, loading}) {
    const {translations} = useContext(LanguageContext);
    const navigate = useNavigate();
    const [filteredsensors, setFilteredsensors] = useState([]);
    const [favori, setFavori] = useState(JSON.parse(localStorage.getItem('favoris')) || {});
    const [selectedValve, setSelectedValve] = useState(null);
    const [userLatitude, setUserLatitude] = useState(null);
    const [userLongitude, setUserLongitude] = useState(null);

    const [error, setError] = useState('');

    const handleGeoLocation = () => {
        if (!navigator.geolocation) {
            setError(translations["La géolocalisation n'est pas supportée par ce navigateur."]);
            return;
        }

        navigator.geolocation.getCurrentPosition(
            (position) => {
                const {latitude, longitude} = position.coords;
                setUserLatitude(latitude)
                setUserLongitude(longitude)
                setError('');
            },
            (error) => {
                let error_message = "";
                switch (error.message) {
                    case "User denied Geolocation":
                        error_message = translations["Géolocalisation refusée par l'utilisateur"]
                        break;
                    default:
                        error_message = error.message
                        break;
                }
                setError(translations["Erreur de géolocalisation"] + " : " + error_message);
            }
        );
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const {latitude, longitude} = position.coords;
                setUserLatitude(latitude);
                setUserLongitude(longitude);
            },
            (error) => {
                console.warn(error);
            },
            {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0
            }
        );
    }, []);
    const goToHome = (sensor_type_id, sensor_id, sensor_name) => {
        navigate(`/${objDetail}/` + sensor_type_id + "_" + sensor_id + "_" + sensor_name);  // Naviguer vers la page d'accueil
    };
    const handleFavoriteClick = (valve) => {
        // Vérifier si la valve est déjà le favori
        const currentFavorite = JSON.parse(localStorage.getItem('favoris'));

        // Afficher une alerte de confirmation
        const confirmChange = !currentFavorite
            ? window.confirm(translations["Voulez-vous définir ce capteur comme votre favori ?"])
            : window.confirm(translations["Voulez-vous remplacer votre favori par ce capteur ?"]);

        if (confirmChange) {
            localStorage.setItem('favoris', JSON.stringify(valve));
            setFavori(valve);
        }
    };

    useEffect(() => {
        let dataToDisplay = [...data];

        // Ajouter le favori en tête de liste si la liste des données est vide
        if (dataToDisplay.length === 0 && favori) {
            dataToDisplay = [favori];
        } else if (data.length > 0) {
            // Marquer le capteur comme favori s'il correspond au favori stocké
            dataToDisplay = data.map(item => ({
                ...item,
                isFavorite: favori && item.sensor_id === favori.sensor_id,
                distance: item.distance ? item.distance : calculateDistance(userLatitude, userLongitude, item.coord_x, item.coord_y)
            }));

            // Trier les capteurs, en plaçant le favori en haut
            dataToDisplay.sort((a, b) => {
                if (a.isFavorite) return -1;
                if (b.isFavorite) return 1;
                if (a.distance === null) return 1;
                if (b.distance === null) return -1;
                return a.distance - b.distance;
            });
        }
        setFilteredsensors(dataToDisplay);
    }, [userLatitude, userLongitude, data, favori]);

    function toRadians(degrees) {
        return degrees * (Math.PI / 180);
    }

    function calculateDistance(lat1, lon1, lat2, lon2) {
        if (lat1 == null || lon1 == null || lat2 == null || lon2 == null) {
            return null
        }
        var earthRadiusKm = 6371;

        var dLat = toRadians(lat2 - lat1);
        var dLon = toRadians(lon2 - lon1);

        lat1 = toRadians(lat1);
        lat2 = toRadians(lat2);

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return parseInt(earthRadiusKm * c)
    }

    const options = data.map((option) => {
        const firstLetter = option.sensor_name[0].toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option,
            sensor_type_path_image: option.sensor_type_path_image
        };
    });
    let closerSensor = data.length > 0 && (data[0].distance !== null || (data[0].coord_x !== null && data[0].coord_x !== null)) && data[0];
    if (filteredsensors.length > 0) {
        const sensorsWithDistance = filteredsensors.filter(value => value.distance !== null);
        if (sensorsWithDistance.length > 0) {
            closerSensor = sensorsWithDistance.reduce((previousValue, currentValue) => currentValue.distance > previousValue.distance ? previousValue : currentValue, sensorsWithDistance[0]);
        }
    }
    const handleLogout = () => {
        // Logique de déconnexion : Suppression du token, redirection, etc.
        localStorage.removeItem(auth);
        navigate(`/${defaultLang}/${auth}`);
    };

    let footerTools = <div className="sticky-logout">
        <button className={"logout-button"} onClick={handleLogout}><FaPowerOff className={"icon-logout"}/></button>
    </div>
    if (data && data.length < 1 && favori) {
        if (JSON.stringify(favori) !== "{}") {
            data = [favori]
        } else {
            return (
                <div>
                    <div className={"fixed-header-logo"}>
                        <div className="logo-header">
                            <img src={logoPath} alt="Logo Argos en jaune" className={"logo-carboparc"}/>
                        </div>
                        <div>
                            {loading
                                ? <span>{translations["Chargement en cours"]}<LoaderIcon/></span>
                                : <div>
                                    {(userLatitude && userLongitude) ?
                                        <div>
                                            <h1>{translations["Vous n'avez pas de machine dans votre zone actuellement."]}</h1>
                                            <br/>
                                            <div style={{fontStyle: "italic", fontSize: 9}}>
                                                {
                                                    translations["Astuce: lorsque vous êtes en présence de votre machine, pour y garder un oeil n'oubliez pas de le mettre en favori, en cliquant sur le bouton comme ci-dessous."]
                                                }
                                            </div>
                                            <img
                                                alt={translations["Icône ronde jaune avec un symbole de validation blanc"]}
                                                src={img_favoris}/>
                                        </div>
                                        : <div>
                                            <h1>{translations["Merci d'activer la Géolocalisation, pour avoir accès à la liste de vos machines connectées"]}</h1>

                                            <button className="geolocalisation-button" onClick={handleGeoLocation}>
                                                {translations["Activer la géolocalisation"]}
                                            </button>
                                            {error ?
                                                <div style={{color: 'red'}}>
                                                    <strong>{error}</strong>
                                                </div> : null}
                                            {userLatitude && userLongitude ? (
                                                <div>
                                                    <h3>{translations["Coordonnées actuelles:"]}</h3>
                                                    <p>{translations["Latitude"]}: {userLatitude}</p>
                                                    <p>{translations["Longitude"]}: {userLongitude}</p>
                                                </div>
                                            ) : null}
                                        </div>}
                                </div>
                            }
                        </div>
                    </div>
                    {footerTools}
                </div>);
        }
    }
    // console.log(closerSensor)
    return (
        <div>
            <div className={"fixed-header-logo"}>
                <div className="logo-header">
                    <img src={logoPath} alt="Logo Argos en jaune" className={"logo-carboparc"}/>
                </div>
                <div className="header-group filter-tools-bar">
                    <Autocomplete
                        id="grouped-demo"
                        options={options.sort((a, b) => -b.sensor_type_name.localeCompare(a.sensor_type_name))}
                        groupBy={(option) => option.sensor_type_name}
                        getOptionLabel={(option) => option.sensor_name}
                        renderGroup={(groupParams) => (
                            <li key={groupParams.key}>
                                {/*<img src={groupParams.sensor_type_path_image} alt={translations["icon du type de machine"]} style={{ width: '24px', height: '24px' }} />*/}
                                <span className={"sensor_type_name"}>{groupParams.group}</span>
                                <ul>
                                    {groupParams.children}
                                </ul>
                            </li>
                        )}
                        sx={{width: "100%"}}
                        onChange={(event, newValue) => {
                            setSelectedValve(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label={translations["Recherche"]}/>}
                    />
                </div>

            </div>
            <table className="rwd-table">
                <tbody className={"item-tbody"}>
                {filteredsensors.filter(sensor => !selectedValve || sensor.sensor_id === selectedValve.sensor_id).map(sensor => {
                    let iconFavoris = favori.sensor_id === sensor.sensor_id
                        ? <td className={"image-container"}>
                            <img alt={translations["Icône ronde jaune avec un symbole de validation blanc"]}
                                 src={img_favoris}/>
                        </td>
                        :
                        <td className={"image-container clickable"} onClick={() => handleFavoriteClick(sensor)}>
                            <img alt={translations["Icône ronde gris avec un symbole de validation blanc"]}
                                 src={img_non_favoris}/>
                        </td>
                    let ligneClass = closerSensor.distance !== null && closerSensor.distance === sensor.distance ? "item-container closer-sensor" : "item-container";


                    return <tr key={sensor.sensor_id} className={ligneClass}>
                        {iconFavoris}
                        <td className={"text-name"} onClick={() => {
                            goToHome(sensor.sensor_type_id, sensor.sensor_id, sensor.sensor_name)
                        }}>
                            {sensor.sensor_name}
                        </td>
                        <td className={"text-distance"}>
                            {sensor.distance ? (sensor.distance < 1 ? (sensor.distance * 1000).toFixed(2) + " m" : sensor.distance.toFixed(2) + " km") :
                                <MdLocationOff className={"localisation-off"}/>}
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
            {footerTools}
        </div>
    );
}

export default Dashboard;
