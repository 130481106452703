import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './ressources/fonts/_fonts.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import InstallPopup from './installPopup.js';

// Registro do Service Worker
if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
    navigator.serviceWorker.register('/service-worker.js')
        .then((registration) => {
            console.log('Service Worker registrado com sucesso:', registration);
        })
        .catch((error) => {
            console.error('Erro ao registrar o Service Worker:', error);
        });
}

// Inicialização do React
const rootElement = document.getElementById('root');

// Verifica se o elemento root existe
if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <React.StrictMode>
            <BrowserRouter basename="/">
                <App />
                <InstallPopup />
            </BrowserRouter>
        </React.StrictMode>
    );
} else {
    console.error('Elemento root não encontrado no DOM.');
}

// Métricas de desempenho (opcional)
reportWebVitals(console.log);
