import React, { useContext, useState, useEffect } from 'react';
import { LanguageContext } from '../../LanguageContext'; // Ajustez le chemin selon votre structure
import './Auth.css';
import logoPath from '../../ressources/images/logo itm plus.png';
import LoaderIcon from "../../ressources/tools/LoaderIcon";
import { hashPasswordSHA1 } from "../../ressources/tools/hashPasswordSHA1";
import sendPostRequest from '../../ressources/tools/sendPostRequest'; // Ajustez le chemin selon votre structure

const Auth = ({ reqConnexion, loading }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isResetForm, setIsResetForm] = useState(false); // Estado para alternar entre os formulários
    const { translations } = useContext(LanguageContext);

    // Recuperar o username e password do localStorage quando o componente for montado
    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedPassword = localStorage.getItem('password'); // NÃO RECOMENDADO
        if (storedUsername) {
            setUsername(storedUsername);
        }
        if (storedPassword) {
            setPassword(storedPassword); // NÃO RECOMENDADO
        }
    }, []);

    // Armazenar o username e password no localStorage
    const storeCredentials = (username, password) => {
        localStorage.setItem('username', username);
        localStorage.setItem('password', password); // NÃO RECOMENDADO
    };

    function peutObtenirLocalisation() {
        return new Promise((resolve, reject) => {
            if (!("geolocation" in navigator)) {
                reject("La géolocalisation n'est pas supportée par ce navigateur.");
                return;
            }

            navigator.geolocation.getCurrentPosition(
                () => resolve({ peutObtenir: true, autorise: true }), // Localisation réussie
                (erreur) => {
                    if (erreur.code === erreur.PERMISSION_DENIED) {
                        resolve({ peutObtenir: true, autorise: false }); // Localisation possible mais non autorisée
                    } else {
                        resolve({ peutObtenir: true, autorise: false }); // Autres erreurs de localisation
                    }
                }
            );
        });
    }

    async function verifierLocalisationEtDemanderAutorisation() {
        try {
            const { peutObtenir, autorise } = await peutObtenirLocalisation();

            if (peutObtenir && !autorise) {
                alert("Votre localisation est obligatoire pour que l'application fonctionne correctement. Merci de l'activer, sinon l'application ne pourra pas être utilisée.");
            } else if (peutObtenir && autorise) {
                console.log("Localisation obtenue avec succès.");

                const hashedPassword = await hashPasswordSHA1(password);
                if (!loading && username !== "" && password !== "") {
                    storeCredentials(username, password); // Armazenar o username e a senha ao fazer login
                    reqConnexion(username, hashedPassword);
                }
            } else {
                console.log("Impossible d'obtenir la localisation.");
                alert(translations["Impossible d'obtenir la localisation."])
            }
        } catch (erreur) {
            console.error(erreur);
            alert(translations["La géolocalisation n'est pas supportée par le navigateur, merci d'utiliser Google chrome"])
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        verifierLocalisationEtDemanderAutorisation();
    };

    const handlePasswordResetRequest = () => {
        setIsResetForm(true); // Alterna para o formulário de reset
    };

    const handleMessageConnexionSpiebotignolles = () => {
        alert(translations["La connexion Spie Batignolles est actuellement en train de s'établir."]);
    };

    const handleBackToLogin = () => {
        setIsResetForm(false); // Alterna de volta para o formulário de login
    };

    const handleResetSubmit = async (event) => {
        event.preventDefault();
    
        const data = {
            "request_type": 11006,
            email: username
        };
    
        try {
            const response = await sendPostRequest(data);
            
            if (response && response.state === false && response.errors.includes("Email does not exist.")) {
                alert("L'adresse e-mail n'existe pas."); // Alerta para e-mail não existente
            } else if (response && response.state === true) {
                alert("Un e-mail de réinitialisation de mot de passe a été envoyé à votre adresse e-mail.");
            } else {
                // Caso haja outro tipo de erro
                alert(translations["Erreur lors de l'envoi de la demande de réinitialisation du mot de passe."]);
            }
        } catch (error) {
            console.error("Erreur lors de l'envoi de la demande de réinitialisation:", error);
            alert(translations["Erreur lors de l'envoi de la demande de réinitialisation du mot de passe."]);
        }
    };
    
    const FormLogin = (
        <div className="auth-box">
            <img src={logoPath} alt="Logo Argos en jaune" className={"logo"} />
            <form className="auth-form" onSubmit={handleSubmit}>
                <label htmlFor="username" className="login-label">{translations["Email"]}</label>
                <input
                    type="email"
                    id="username"
                    className="login-input"
                    placeholder={translations["votrenom@entreprise.com"]}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                <label htmlFor="password" className="login-label">{translations["Mot de passe"]}</label>
                <input
                    type="password"
                    id="password"
                    className="login-input"
                    placeholder={translations["Mot de passe"]}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <a href="#motdepasseoublier" onClick={handlePasswordResetRequest}
                    className="forgot-password">{translations["Demande de réinitialisation du mot de passe ?"]}</a>
                <button type="submit" className="login-button">
                    {loading ? <span>{"En cours"}<LoaderIcon /></span> : "Connexion"}
                </button>
            </form>
            <button type="button" className="login-button sso" onClick={handleMessageConnexionSpiebotignolles}>
                {translations["Connexion Spie batignolles"]}
            </button>
        </div>
    );

    const FormReset = (
        <div className="auth-box">
            <img src={logoPath} alt="Logo Argos en jaune" className={"logo"} />
            <form className="auth-form" onSubmit={handleResetSubmit}>
                <span className="forgot-password">Demande de réinitialisation du mot de passe</span>
                <label htmlFor="username" className="login-label">{translations["Email"]}</label>
                <input
                    type="email"
                    id="username"
                    className="login-input"
                    placeholder={translations["votrenom@entreprise.com"]}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                <button type="submit" className="login-button pad">
                    <span className='msgReset'>Envoyer</span>
                </button>
                <button type="button" className="login-button" onClick={handleBackToLogin}>
                    <span className='msgReset'>Retour au login</span>
                </button>
            </form>
        </div>
    );

    return (
        <>
            {isResetForm ? FormReset : FormLogin}
        </>
    );
};

export default Auth;
