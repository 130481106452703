import React from "react";
import degrade_de_couleur from "../../ressources/images/degrade_de_couleurNew.svg";

const BarProgression = ({ pourcentage }) => {
  // Adapter le canvas à la taille de l'écran
  const vw = Math.min(window.innerWidth);
  const vh = Math.max(window.innerHeight);
  const width = 0.8 * vw + 0.005 * vh;
  const height = 0.008 * vw + 0.02 * vh;
  const diametre_cercle = 0.0 * width + 1 * height;
  const ombre = 0.002 * vw + 0.002 * vh;

  const max = width - diametre_cercle - diametre_cercle / 5;
  
  // Garantir que 'valeur' esteja entre 0 e 1
  const valeur = Math.max(0, Math.min(1, pourcentage / 100));

  return (
    <div
      style={{
        position: "relative",
        width: width,
        height: height,
        borderRadius: width,
        backgroundImage: `url(${degrade_de_couleur})`,
        backgroundSize: "100%",
        boxShadow: `${ombre}px ${ombre}px 2px 1px rgba(0, 0, 255, .2)`,
        overflow: "visible",
      }}
    >
      {(pourcentage !== null && pourcentage !== undefined) && (
        <div
          style={{
            height: diametre_cercle,
            width: diametre_cercle,
            position: "absolute",
            margin: `${diametre_cercle}px 0`,
            top: -diametre_cercle - diametre_cercle / 5,
            left: max * valeur,
            backgroundColor: "rgba(0,0,0,0)",
            border: `${diametre_cercle / 5}px solid white`,
            borderRadius: "100%",
            boxShadow: 'rgba(0, 0, 255, 0.2) 0px 1px 2px 1px',
            overflow: "visible",
          }}
        />
      )}
    </div>
  );
};

export default BarProgression;
