import React, { useEffect, useState } from 'react';
import './installPopup.css';
import { LiaArrowCircleDownSolid } from "react-icons/lia";
import logoPath from "./ressources/images/logo + carboparc.png"
import {AiOutlineClose} from "react-icons/ai";
const InstallPopup = () => {
  const [evenementPromptInstall, setEvenementPromptInstall] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  useEffect(() => {
    const gestionnaireBeforeInstallPrompt = (e) => {
      e.preventDefault(); // Empêcher l'affichage de la mini-barre sur mobile
      setEvenementPromptInstall(e); // Sauvegarder l'événement pour pouvoir le déclencher plus tard
    };

    window.addEventListener('beforeinstallprompt', gestionnaireBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', gestionnaireBeforeInstallPrompt);
    };
  }, []);

  const gererClicInstaller = () => {
    if (!evenementPromptInstall) return;

    evenementPromptInstall.prompt(); // Afficher le prompt d'installation

    evenementPromptInstall.userChoice.then((resultatChoix) => {
      if (resultatChoix.outcome === 'accepted') {
        console.log("L'utilisateur a accepté l'installation du PWA");
      } else {
        console.log("L'utilisateur n'a pas accepté l'installation du PWA");
      }

      setEvenementPromptInstall(null); // Réinitialiser l'événement de prompt, puisqu'il ne peut être utilisé qu'une fois.
    });
  };

  const togglePopupN = () => {
    setIsPopupVisible(!isPopupVisible);
};
  return (
    <div className={isPopupVisible ? 'closeall': ''} >
    <div className={evenementPromptInstall ? 'backB': 'backBNew'}>
      {evenementPromptInstall && (
        <div className='popup'>
               
                <button onClick={togglePopupN} className='closeIn'>
                <AiOutlineClose className={"close-iconPopup"}/>
                </button>
               
              <button className='btnInstall' onClick={gererClicInstaller}>
             
             
              <div> <div> <img className='logoInstallationMsg' src={logoPath} /> </div><span className='msgInstall'>Installer l'application</span>  <span className='iconN'> <LiaArrowCircleDownSolid size={30} color={"#3C3935"}/></span></div>
              
              </button>
        
        </div>
      )}
    </div>
    </div>
  );
};

export default InstallPopup;
