import React, {useContext, useState} from 'react';
import 'leaflet/dist/leaflet.css';
import {Link} from "react-router-dom";
import {dashboard} from "../../references/link";
import './Header.css';
import {AiOutlineClose} from "react-icons/ai";
// import {IoInformationSharp} from "react-icons/io5";
import i from "../../ressources/images/i.svg"
import {LanguageContext} from "../../LanguageContext";

const Header = (props) => {
    const {translations} = useContext(LanguageContext);

    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const togglePopup = () => {
        setIsPopupVisible(!isPopupVisible);
    };

    const Popup = () => (
        <div className="popup-info">
            {props.infoPopup}
            <button onClick={togglePopup} className='btClose'>
            <AiOutlineClose className={"close-iconPopup"}/>
            </button>
        </div>
    );

    return (
        <div className="header-group Header">
            <span className={"link"} onClick={togglePopup}>
                {/*<IoInformationSharp className={"info-icon"}/>*/}
                <img src={i} alt={translations["icon information"]} className={"info-image"}/>
            </span>
            {isPopupVisible && (
                <>
                    <div className="modal-backdrop" onClick={togglePopup}/>
                    <Popup />
                </>
            )}
            <h2>{props.titre}</h2>
            <Link className={"link"} to={`/${dashboard}`}>
                <AiOutlineClose className={"close-icon"}/>
            </Link>
        </div>
    );
};

export default Header;
