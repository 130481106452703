import React, {useContext, useEffect, useState} from 'react';
import './CapteurDetails.css';
import Header from "../views/Header/Header";
import {useParams} from "react-router-dom";
import DAY from "../ressources/images/dayW.svg"
import WEEK from "../ressources/images/weekW.svg"
import MOIS from "../ressources/images/moisW.svg"
import YEAR from "../ressources/images/yearW.svg"
import img_CO2 from "../ressources/images/CO2.svg"
import img_consommation from "../ressources/images/consommation.svg"
import img_objectif from "../ressources/images/objectif.svg"
import img_conseil from "../ressources/images/conseil.svg"
import {LanguageContext} from "../LanguageContext";
import CO2 from "./CO2/CO2";
import Consommation from "./Consommation/Consommation";
import Objectif from "./Objectif/Objectif";
import Conseil from "./Conseil/Conseil";
import { useLocation } from 'react-router-dom';
import sendPostRequest from "../ressources/tools/sendPostRequest";
import {
    mediane_max_pour_un_type_sur_30j,
    recuperer_le_cumule_d_un_engin,
    recuperer_les_donnees_de_fonctionnement_et_de_ralenti_par_engin,
    recuperer_liste_des_debit_d_un_engin,
    recuperer_liste_des_debit_par_type
} from "../references/glossaire_requetes";
import {annee, heure, jour, mois, semaine, trimestre} from "../references/glossaire_frequence";
import {
    getStartAndEndOfDay,
    getStartAndEndOfMonth,
    getStartAndEndOfYear,
    getStartOfWeek
} from "../ressources/tools/getIntervalTools";
import LoaderIcon from "../ressources/tools/LoaderIcon";

import {carburant2CO2} from "../references/glossaire_carburant2CO2";

function CapteurDetails() {
    const {translations} = useContext(LanguageContext);
    const getCurrentDateAtMidnightUTC = () => {
        // Crée un objet Date pour minuit aujourd'hui dans la time zone locale
        const now = new Date();
        const localMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate());

        // Convertit cette date locale en UTC
        return new Date(Date.UTC(localMidnight.getFullYear(), localMidnight.getMonth(), localMidnight.getDate())).getTime() / 1000;
    };

    const ongletGeneral = [
        {
            name: "CO2",
            icon: <img className={"icon-onglet"} src={img_CO2} alt={translations["icon de CO2"]}/>,
            infoPopup: <>
                <div className='titre'>Empreinte carbone relative</div>
                <div className='cors'>
                    <p> L’empreinte carbone relative <span className='green'>ici -55 kgCO2/h</span> est l’écart de
                        consommation de CO2 de l’engin sélectionné par rapport à la consommation moyenne d’un groupe
                        d’engins de même type depuis le début de la période retenue (jour, semaine, mois, année).
                    </p>


                    <p>Sur cet écran, la mention indique que <span className='green'>l’engin sélectionné rejette une quantité de CO2 inférieur de 55kg à la moyenne des autres engins du même type.</span>
                    </p>

                    <p>Ces informations permettent de se rendre compte de l’empreinte carbone relative de la
                        machisne utilisée en fonction de son historique d’utilisation.</p>
                </div>
            </>
        },
        {
            name: "consommation",
            icon: <img className={"icon-onglet"} src={img_consommation} alt={translations["icon de consommation"]}/>,
            infoPopup: <>
                <div className='titre'>Consommation de carburant</div>
                <div className='cors'>
                    <p><strong>Débit moyen : </strong><span className='green'>ici 15,5 l/h</span>
                        Il s’agit du débit moyen de carburant calculé sur la dernière sous période correspondant au
                        créneau temporel retenu, journée, semaine entamée, mois ou année en cours.
                    </p>
                    <table>
                        <thead>
                        <tr>
                            <th>Période (1)</th>
                            <th>Sous période (2)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Jour</td>
                            <td>12 dernières heures</td>
                        </tr>
                        <tr>
                            <td>Semaine</td>
                            <td>7 derniers jours</td>
                        </tr>
                        <tr>
                            <td>Mois</td>
                            <td>12 dernières semaines</td>
                        </tr>
                        <tr>
                            <td>Année</td>
                            <td>12 derniers mois</td>
                        </tr>
                        </tbody>
                    </table>
                    <p>
                        <strong>Poids équivalent de CO2 rejeté en une heure (en kg CO2/h) :</strong>
                        <span className='green'> ici 45 kg CO2/h</span>
                        Le poids de CO2 équivalent est calculé en multipliant le débit moyen par le poids en CO2
                        équivalent d’un litre du carburant indiqué
                    </p>

                    <table>
                        <thead>
                        <tr>
                            <th>Carburant</th>
                            <th>Poids CO2 équivalent</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Essence</td>
                            <td>2.29 kg CO2/l</td>
                        </tr>
                        <tr>
                            <td>Essence E10 10% éthanol</td>
                            <td>2.21 kg CO2/l</td>
                        </tr>
                        <tr>
                            <td>Essence E85 85% éthanol</td>
                            <td>1.61 kg CO2/l</td>
                        </tr>
                        <tr>
                            <td><strong>Diesel</strong></td>
                            <td><strong>2.66 kg CO2/l</strong></td>
                        </tr>
                        <tr>
                            <td>Diesel B5 5% biodiésel</td>
                            <td>2.66 kg CO2/l</td>
                        </tr>
                        <tr>
                            <td>Diesel B5 20% biodiésel</td>
                            <td>2.66 kg CO2/l</td>
                        </tr>
                        </tbody>
                    </table>
                    <p>
                        <strong>Moyenne du débit correspondant</strong>
                        La valeur du débit moyen représentée est calculé à partir de la médiane des débits de carburant
                        d’un groupe d’engins de même type, sur une période de référence.
                        Cette période de référence correspond aux 30 derniers jours de fonctionnement de chaque engin du
                        même type.
                    </p>
                </div>
            </>
        },
        {
            name: "objectif",
            icon: <img className={"icon-onglet"} src={img_objectif} alt={translations["icon de objectif"]}/>,
            infoPopup: <>
                <div className='titre'>Budget CO2</div>
                <div className='cors'>
                    <p>
                        Le budget CO2 de l’engin (en kg CO2) sur une période sélectionnée (jour, semaine, mois, année)
                        est calculé par extrapolation de la moyenne de consommation d’un ensemble d’engins de même type
                        sur une période de référence.
                    </p>
                    <p>
                        Cette période de référence correspond aux 30 derniers jours de fonctionnement de chaque engin du
                        même type.
                        Le pourcentage de remplissage de la feuille permet de représenter la quantité de CO2 consommée
                        par rapport au budget CO2 prévu pour la période retenue. <span className='green'>Ici, le budget est entièrement disponible car la valeur consommée est de 0kg.</span>
                    </p>
                    <p>
                        Note: lors du dépassement du budget la quantité de CO2 consommée apparaît <span className='red'>en rouge</span>.
                    </p>
                </div>
            </>
        },
        {
            name: "conseil",
            icon: <img className={"icon-onglet"} src={img_conseil} alt={translations["icon de conseil"]}/>,
            infoPopup: <>
                <div className='titre'>Conseils</div>
                <div className='cors'>
                    <p>
                        Cet écran permet de visualiser la performance carbone de l’engin sélectionné sur la période
                        retenue (jour, semaine, mois, année) selon ses phases de fonctionnement : pendant ses périodes
                        au ralenti ou lorsqu’il travaille :
                    </p>
                    <p>
                        <strong>Fonctionnement</strong> indique comment se place l’engin, lorsqu’il travaille, par
                        rapport au maximum et au minimum de consommation du groupe d’engins de même type sur la période
                        de référence.
                    </p>
                    <p>
                        <strong>Ralenti</strong> indique comment se situe la consommation de l’engin, au ralenti, par
                        rapport au maximum et au minimum de consommation au ralenti du groupe d’engins de même type sur
                        la période de référence.
                    </p>
                    <p>
                        Au-dessous du positionnement de l’empreinte relative au ralenti est affiché <span
                        className='green'>le temps cumulé de ralenti sur le temps pendant lequel le contact est allumé.</span>
                    </p>
                    <p>
                        Des conseils spécifiques pour les différents niveaux d’empreintes relatives sont affichés pour
                        délivrer les conseils d’écoconduite les plus pertinents.
                    </p>
                </div>
            </>
        }
    ]
    const location = useLocation();
    
    const [infoPopup, setInfoPopup] = useState(ongletGeneral[0].infoPopup);
    const {id} = useParams(); // Cela extrait l'ID de l'URL
    const [sendReqMedianeMax, setSendReqMedianeMax] = useState(true);
    const [mediane, setMediane] = useState(null);
    const [maxDebit, setMaxDebit] = useState(null);
    const [datesConsommation, setDatesConsommation] = useState(null);
    const [valuesConsommation, setValuesConsommation] = useState(null);
    const [engineeringUnitConso, setEngineeringUnitConso] = useState("");
    const [frequence, setFrequence] = useState(null);
    const [seuilRalenti, setSeuilRalenti] = useState(null);
    const [debitFonctionnement, setDebitFonctionnement] = useState(null);
    const [debitRalenti, setDebitRalenti] = useState(null);
    const [tempsTravail, setTempsTravail] = useState(null);
    const [tempsRalenti, setTempsRalenti] = useState(null);
    const [cumulLitresTravailObjectif, setCumulLitresTravailObjectif] = useState(null);
    const [sendReqOnglet, setSendReqOnglet] = useState(true);
    const [debitsList, setDebitsList] = useState(null);
    const [indexSensor, setIndexSensor] = useState();
    const [type_id, setType_id] = useState(null);
    const [sensor_id, setSensor_id] = useState(null);
    const [sensor_name, setSensor_name] = useState(null);
    const [loading, setLoading] = useState(false);
    const [erreurReq, setErreurReq] = useState(false);
    const [engineeringUnitCO2, setEngineeringUnitCO2] = useState("");
    //nouvelles cles pour utilse dans le req 11001 Objectif
    const [max_debit_fonctionnementN, setMax_debit_fonctionnementN] = useState(null);
    const [max_debit_ralentiN, setMax_debit_ralentiN] = useState(null);
    const [min_debit_ralenti, setMin_debit_ralenti] = useState(null);

    //nouvelles cles pour utilse dans le req 11004 Objectif
    const [cumul_litres_fonctionnementN, setCumul_litres_fonctionnementN] = useState(null);
    const [cumul_litres_ralentiN, setCumul_litres_ralentiN] = useState(null);

    //nouvelles cles pour utilse dans le req 11004 Objectif
    const [temps_fonctionnementN, setTemps_fonctionnementN] = useState(null);

    //nouvelles cles pour utilse dans le req 11004 Objectif
    const [mediane_debitN, setMediane_debitN] = useState(null);
    const [mediane_temps_fonctionnementN, setMediane_temps_fonctionnementN] = useState(null);
    const [mediane_debit_fonctionnement, setMediane_debit_fonctionnement] = useState(null);


    //nouvelles cles pour utilse dans le req 11004 comsomation
    const [debit_general, setDebit_general] = useState(null);
    const [max_debit_general, setMax_debit_general] = useState(null);
    const [min_debit_general, setMin_debit_general] = useState(null);
 
    const [carburant, setCarburant] = useState(() => {
        // Recupera o carburante salvo no localStorage, se existir
        const savedCarburant = localStorage.getItem('selectedCarburant');
        return savedCarburant ? savedCarburant : "gazole"; 
    });
    
    useEffect(() => {
        // Sempre que o carburante mudar, salva no localStorage
        localStorage.setItem('selectedCarburant', carburant);
    }, [carburant]);
    
    const carburantSelector = (
        <select
            className="consoGazoleParHeureSelect"
            id="carburant-select"
            value={carburant}
            onChange={(e) => setCarburant(e.target.value)}
        >
            {Object.keys(carburant2CO2).map((nomCarburant) => (
                <option
                    key={nomCarburant} // Adicionei a propriedade 'key' para cada opção
                    className="consoGazoleParHeureSelectOption"
                    value={nomCarburant}
                >
                    {nomCarburant.charAt(0).toUpperCase() + nomCarburant.slice(1)}
                </option>
            ))}
        </select>
    );


useEffect(() => {
    const path = location.pathname;
    const parts = path.split('/');
    const ids = parts[parts.length - 1].split('_');

    if (ids.length >= 2) {
        const typeId = parseInt(ids[0]);
        const sensorId = parseInt(ids[1]);
        setType_id(typeId);
        setSensor_id(sensorId);
        setSensor_name(ids.length > 2 ? ids[2] : `${typeId}-${sensorId}`);
        console.log('IDs definidos:', { typeId, sensorId });
    } else {
        console.error("A URL não contém as informações esperadas.");
    }
}, [location.pathname]);
    
    useEffect(() => {
        if (type_id) {
            console.log('type_id definido:', type_id);
            setSendReqMedianeMax(true);
            setSendReqOnglet(true);
        }
    }, [type_id]);


    const date_auj_00_00 = getCurrentDateAtMidnightUTC();
    const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const envoieReqApresErreur = () => {
        setErreurReq(false)
        setSendReqOnglet(true)
    };
    const [ongletFrequence, setOngletFrequence] = useState(() => {
        const storedFrequence = localStorage.getItem('ongletFrequence');
        return storedFrequence !== null ? parseInt(storedFrequence, 10) : 0;
    });
    useEffect(() => {
        localStorage.setItem('ongletFrequence', ongletFrequence);
    }, [ongletFrequence]);
    const changementDOngletFrequence = (ongletFrequenceSelectionne) => {
        setOngletFrequence(ongletFrequenceSelectionne)
        setSendReqOnglet(true)
        setErreurReq(false)
    };
    const [activeTab, setActiveTab] = useState(() => {
        const storedTab = localStorage.getItem('activeTab');
        return storedTab && ongletGeneral.some(onglet => onglet.name === storedTab)
            ? storedTab
            : ongletGeneral[0].name;
    });
    
    useEffect(() => {
        // Sempre que activeTab mudar, atualize no localStorage
        localStorage.setItem('activeTab', activeTab);
    }, [activeTab]);
 
    const changementDOngletGeneral = (tabName) => {
        setActiveTab(tabName)
        setSendReqOnglet(true)
        setErreurReq(false)

        switch (tabName) {
            case ongletGeneral[0].name:
                setInfoPopup(ongletGeneral[0].infoPopup)
                break;
            case ongletGeneral[1].name:
                setInfoPopup(ongletGeneral[1].infoPopup);
                break;
            case ongletGeneral[2].name:
                setInfoPopup(ongletGeneral[2].infoPopup);
                break;
            case ongletGeneral[3].name:
                setInfoPopup(ongletGeneral[3].infoPopup);
                break;
            default:
                break;
        }
    };
    
    useEffect(() => {
        if (type_id && sendReqMedianeMax) {
            console.log('Enviando requisição para mediane e maxDebit', { type_id });
            const req = {
                request_type: mediane_max_pour_un_type_sur_30j,
                type_id: type_id,
                date_median_max: date_auj_00_00,
                time_zone: time_zone
            };
    
            const storageKey = `medianeMax_${type_id}_${time_zone}`;
            let storageReq = localStorage.getItem(storageKey);
    
            if (!storageReq || JSON.parse(storageReq).date_median_max !== date_auj_00_00) {
                setLoading(true);
                sendPostRequest(req).then(res => {
                    if (res.state && res.mediane_debit !== undefined && res.max_debit !== undefined) {
                        const expirationTime = Date.now() + 24 * 60 * 60 * 1000; // Expira em 24 horas
                        localStorage.setItem(storageKey, JSON.stringify({...res, date_median_max: date_auj_00_00, expirationTime}));
                        setMediane(res.mediane_debit);
                        setMaxDebit(res.max_debit);
                        setEngineeringUnitCO2(res.engineering_unit);
                        setMax_debit_fonctionnementN(res.max_debit_fonctionnement);
                        setMax_debit_ralentiN(res.max_debit_ralenti);
                        setMin_debit_ralenti(res.min_debit_ralenti);
                    } else {
                        console.warn('Dados inválidos recebidos.');
                        // Decida se mantém os dados antigos ou informa ao usuário
                    }
                }).catch((error) => {
                    console.error('Erro na requisição:', error);
                    // Talvez manter os dados existentes e informar ao usuário
                }).finally(() => {
                    setLoading(false);
                    setSendReqMedianeMax(false);
                });
            } else {
                const storedData = JSON.parse(storageReq);
                // Verificar se os dados expiraram
                if (storedData.expirationTime && Date.now() > storedData.expirationTime) {
                    localStorage.removeItem(storageKey);
                    setSendReqMedianeMax(true); // Envia nova requisição
                } else {
                    setMediane(storedData.mediane_debit);
                    setMaxDebit(storedData.max_debit);
                    setEngineeringUnitCO2(storedData.engineering_unit);
                    setMax_debit_fonctionnementN(storedData.max_debit_fonctionnement);
                    setMax_debit_ralentiN(storedData.max_debit_ralenti);
                    setMin_debit_ralenti(storedData.min_debit_ralenti);
                    setSendReqMedianeMax(false);
                }
            }
        }
    }, [type_id, sendReqMedianeMax, date_auj_00_00, time_zone, min_debit_ralenti]);
    
    
    useEffect(() => {
        if (!erreurReq && (type_id || sensor_id) && sendReqOnglet) {
            console.log('Enviando requisição para dados do onglet', { type_id, activeTab, ongletFrequence });
    
            let req = {
                time_zone: time_zone,
            };
    
            // Configurar 'req' e 'interval' com base em 'ongletFrequence' e 'activeTab'
            let interval;
            switch (ongletFrequence) {
                case 0: { // Dia
                    req["frequence"] = jour;
                    interval = getStartAndEndOfDay(new Date());
                    if (activeTab === ongletGeneral[1].name) {
                        req["frequence"] = heure;
                    }
                    break;
                }
                case 1: { // Semana
                    req["frequence"] = semaine;
                    interval = getStartOfWeek(new Date());
                    if (activeTab === ongletGeneral[1].name) {
                        req["frequence"] = jour;
                    }
                    break;
                }
                case 2: { // Mês
                    req["frequence"] = mois;
                    interval = getStartAndEndOfMonth(new Date());
                    if (activeTab === ongletGeneral[1].name) {
                        req["frequence"] = semaine;
                    }
                    break;
                }
                case 3: { // Ano
                    req["frequence"] = annee;
                    interval = getStartAndEndOfYear(new Date());
                    if (activeTab === ongletGeneral[1].name) {
                        req["frequence"] = trimestre;
                    }
                    break;
                }
                default:
                    break;
            }
    
            setFrequence(req["frequence"]);
    
            // Configurar 'start_interval' e 'end_interval'
            req["start_interval"] = interval.start_interval;
            req["end_interval"] = interval.end_interval;
    
            // Configurar 'req' e 'storageKey' com base em 'activeTab'
            let storageKey;
            switch (activeTab) {
                case ongletGeneral[0].name: { // CO₂
                    req["request_type"] = recuperer_liste_des_debit_par_type;
                    req["type_id"] = type_id;
                    storageKey = `ongletData_${type_id}_${activeTab}_${ongletFrequence}`;
                    break;
                }
                case ongletGeneral[1].name: { // Consommation
                    req["request_type"] = recuperer_liste_des_debit_d_un_engin;
                    req["sensor_id"] = sensor_id;
                    storageKey = `ongletData_${sensor_id}_${activeTab}_${ongletFrequence}`;
                    break;
                }
                case ongletGeneral[2].name: { // Objectif
                    req["request_type"] = recuperer_le_cumule_d_un_engin;
                    req["sensor_id"] = sensor_id;
                    storageKey = `ongletData_${sensor_id}_${activeTab}_${ongletFrequence}`;
                    break;
                }
                case ongletGeneral[3].name: { // Conseil
                    req["request_type"] = recuperer_les_donnees_de_fonctionnement_et_de_ralenti_par_engin;
                    req["sensor_id"] = sensor_id;
                    storageKey = `ongletData_${sensor_id}_${activeTab}_${ongletFrequence}`;
                    break;
                }
                default: {
                    storageKey = `ongletData_${type_id}_${activeTab}_${ongletFrequence}`;
                    break;
                }
            }
    
            // Verificar se os dados estão no localStorage
            let storageReq = localStorage.getItem(storageKey);
    
            if (
                !storageReq ||
                JSON.parse(storageReq).start_interval !== req["start_interval"] ||
                JSON.parse(storageReq).end_interval !== req["end_interval"]
            ) {
                setLoading(true);
    
                sendPostRequest(req)
                    .then((res) => {
                        if (res.state) {
                            const dataToStore = {
                                ...res,
                                start_interval: req["start_interval"],
                                end_interval: req["end_interval"],
                            };
                            localStorage.setItem(storageKey, JSON.stringify(dataToStore));
                            storageReq = JSON.stringify(dataToStore); // Atualizar storageReq com os novos dados
                        } else {
                            localStorage.removeItem(storageKey);
                            setErreurReq(true);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        setErreurReq(true);
                    })
                    .finally(() => {
                        setLoading(false);
                        setSendReqOnglet(false);
    
                        if (storageReq) {
                            // Atualizar estados com base em 'storageReq'
                            const storedData = JSON.parse(storageReq);
                            switch (activeTab) {
                                case ongletGeneral[0].name: { // CO₂
                                    const debits_list = storedData.debits_list;
                                    setDebitsList(debits_list);
                                    const sensorIndex = debits_list.findIndex(
                                        (item) => item.sensor_id === sensor_id
                                    );
                                    setIndexSensor(sensorIndex !== -1 ? sensorIndex : undefined);
                                    break;
                                }
                                case ongletGeneral[1].name: { // Consommation
                                    setDatesConsommation(storedData.t);
                                    setValuesConsommation(storedData.x);
                                    setEngineeringUnitConso(storedData.engineering_unit);
                                    setDebit_general(storedData.debit_general);
                                    setMax_debit_general(storedData.max_debit_general);
                                    setMin_debit_general(storedData.min_debit_general);
                                    break;
                                }
                                case ongletGeneral[2].name: { // Objectif
                                    setCumulLitresTravailObjectif(storedData.cumul_litres_travail);
                                    setCumul_litres_fonctionnementN(storedData.cumul_litres_fonctionnement);
                                    setCumul_litres_ralentiN(storedData.cumul_litres_ralenti);
                                    setTempsTravail(storedData.temps_travail);
                                    setTempsRalenti(storedData.temps_ralenti);
                                    setTemps_fonctionnementN(storedData.temps_fonctionnement);
                                    setMediane_temps_fonctionnementN(storedData.mediane_temps_fonctionnement);
                                    setMediane_debitN(storedData.mediane_debit);
                                    setMediane_debit_fonctionnement(storedData.mediane_debit_fonctionnement);
                                    break;
                                }
                                case ongletGeneral[3].name: { // Conseil
                                    setSeuilRalenti(storedData.seuil_ralenti);
                                    setDebitFonctionnement(storedData.debit_fonctionnement);
                                    setDebitRalenti(storedData.debit_ralenti);
                                    setTempsTravail(storedData.temps_travail);
                                    setTempsRalenti(storedData.temps_ralenti);
                                    break;
                                }
                                default:
                                    break;
                            }
                        }
                    });
            } else {
                // Dados já estão no localStorage, atualizar estados
                const storedData = JSON.parse(storageReq);
                switch (activeTab) {
                    case ongletGeneral[0].name: { // CO₂
                        const debits_list = storedData.debits_list;
                        setDebitsList(debits_list);
                        const sensorIndex = debits_list.findIndex(
                            (item) => item.sensor_id === sensor_id
                        );
                        setIndexSensor(sensorIndex !== -1 ? sensorIndex : undefined);
                        break;
                    }
                    case ongletGeneral[1].name: { // Consommation
                        setDatesConsommation(storedData.t);
                        setValuesConsommation(storedData.x);
                        setEngineeringUnitConso(storedData.engineering_unit);
                        setDebit_general(storedData.debit_general);
                        setMax_debit_general(storedData.max_debit_general);
                        setMin_debit_general(storedData.min_debit_general);
                        break;
                    }
                    case ongletGeneral[2].name: { // Objectif
                        setCumulLitresTravailObjectif(storedData.cumul_litres_travail);
                        setCumul_litres_fonctionnementN(storedData.cumul_litres_fonctionnement);
                        setCumul_litres_ralentiN(storedData.cumul_litres_ralenti);
                        setTempsTravail(storedData.temps_travail);
                        setTempsRalenti(storedData.temps_ralenti);
                        setTemps_fonctionnementN(storedData.temps_fonctionnement);
                        setMediane_temps_fonctionnementN(storedData.mediane_temps_fonctionnement);
                        setMediane_debitN(storedData.mediane_debit);
                        setMediane_debit_fonctionnement(storedData.mediane_debit_fonctionnement);
                        break;
                    }
                    case ongletGeneral[3].name: { // Conseil
                        setSeuilRalenti(storedData.seuil_ralenti);
                        setDebitFonctionnement(storedData.debit_fonctionnement);
                        setDebitRalenti(storedData.debit_ralenti);
                        setTempsTravail(storedData.temps_travail);
                        setTempsRalenti(storedData.temps_ralenti);
                        break;
                    }
                    default:
                        break;
                }
                setSendReqOnglet(false);
            }
        }
    }, [type_id, sensor_id, sendReqOnglet, activeTab, ongletFrequence, erreurReq, ongletGeneral, time_zone, mediane_debit_fonctionnement]);
    
    

    const renderTabContent = () => {
        if (erreurReq) {
            return <button className="btnMsg" onClick={envoieReqApresErreur}>Cet engin est à l'arrêt, pas de données pour cette période sélectionnée</button>;
        } else {
            switch (activeTab) {
                case ongletGeneral[0].name:
                    if (!mediane || !maxDebit || !debitsList || debitsList.length === 0) {
                        return <button className="btnMsg" onClick={() => setSendReqMedianeMax(true)}>Cet engin est à l'arrêt, pas de données pour cette période sélectionnée</button>;
                    }
                    // if (!mediane) {
                    //     return <button className="btnMsg" onClick={() => setSendReqMedianeMax(true)}>Cet engin est à l'arrêt, pas de données pour cette période sélectionnée</button>;
                    // }
                    // if (!debitsList) {
                    //     return <button className="btnMsg" onClick={() => setSendReqOnglet(true)}> 
                    //     Cet engin est à l'arrêt, pas de données pour cette période sélectionnée
                    // </button>;
                    // } else if (debitsList.length === 0) {
                    //     return <button className="btnMsg" onClick={() => setSendReqOnglet(true)}>Cet engin est à l'arrêt, pas de données pour cette période sélectionnée</button>;
                    // }
                    if (!indexSensor) {
                        // todo: sélectionner la machine
                        return <button className="btnMsg" onClick={() => setSendReqMedianeMax(true)}>Cet engin est à l'arrêt, pas de données pour cette période sélectionnée</button>;
                    }
                    return <CO2 mediane={mediane} data={debitsList} indexSensor={indexSensor}
                                carburant={carburant}
                                uniteTemps={frequence}
                                carburantSelector={carburantSelector}
                                engineeringUnitCO2={engineeringUnitCO2}/>;
                case ongletGeneral[1].name:
                    if (!maxDebit) {
                        return <button className="btnMsg" onClick={() => setSendReqMedianeMax(true)}>Cet engin est à l'arrêt, pas de données pour cette période sélectionnée</button>;
                    }
                    if (!datesConsommation || !valuesConsommation || !frequence) {
                        return <button className="btnMsg" onClick={() => setSendReqOnglet(true)}>Cet engin est à l'arrêt, pas de données pour cette période sélectionnée</button>;
                    }
                    return <Consommation datesConsommation={datesConsommation}
                                        valuesConsommation={valuesConsommation}
                                         maxDebit={maxDebit}
                                         engineeringUnit={engineeringUnitConso}
                                         uniteTemps={frequence}
                                         carburant={carburant}
                                         carburantSelector={carburantSelector}
                                         debit_general={debit_general}
                                         min_debit_general={min_debit_general}
                                         max_debit_general={max_debit_general}
                                         
                                         />;
                case ongletGeneral[2].name:
                    if (!maxDebit) {
                        return <button className="btnMsg" onClick={() => setSendReqMedianeMax(true)}>Cet engin est à l'arrêt, pas de données pour cette période sélectionnée</button>;
                    }
                    if (!cumulLitresTravailObjectif || !frequence) {
                        return <button className="btnMsg" onClick={() => setSendReqOnglet(true)}>Cet engin est à l'arrêt, pas de données pour cette période sélectionnée</button>;
                    }
                    return <Objectif cumulLitresTravailObjectif={cumulLitresTravailObjectif} maxDebit={maxDebit}
                                     uniteTemps={frequence}
                                     carburant={carburant}
                                     carburantSelector={carburantSelector}
                                     max_debit_fonctionnementN={max_debit_fonctionnementN}
                                     max_debit_ralentiN={max_debit_ralentiN}
                                     min_debit_ralenti={min_debit_ralenti}
                                     cumul_litres_fonctionnementN={cumul_litres_fonctionnementN}
                                     cumul_litres_ralentiN={cumul_litres_ralentiN}
                                     temps_fonctionnementN={temps_fonctionnementN}
                                     tempsTravail={tempsTravail}
                                     tempsRalenti={tempsRalenti}
                                     mediane={mediane}
                                     mediane_temps_fonctionnementN={mediane_temps_fonctionnementN}
                                     mediane_debitN={mediane_debitN}
                                     mediane_debit_fonctionnement={mediane_debit_fonctionnement}
                                     />;
                case ongletGeneral[3].name:
                    if (!maxDebit) {
                        return <button className="btnMsg" onClick={() => setSendReqMedianeMax(true)}>Cet engin est à l'arrêt, pas de données pour cette période sélectionnée</button>;
                    }
                    if (isNaN(seuilRalenti) || isNaN(debitFonctionnement) || isNaN(debitRalenti) || isNaN(tempsTravail) || isNaN(tempsRalenti)) {
                        return <button className="btnMsg" onClick={() => setSendReqOnglet(true)}>Cet engin est à l'arrêt, pas de données pour cette période sélectionnée</button>;
                    }
                    return <Conseil
                        maxDebit={maxDebit}
                        seuilRalenti={seuilRalenti}
                        debitFonctionnement={debitFonctionnement}
                        debitRalenti={debitRalenti}
                        tempsTravail={tempsTravail}
                        tempsRalenti={tempsRalenti}
                    />;
                default:
                    return <div className="tab-content">Contenu de Consignes</div>;
            }
        }
    };


    // useEffect(() => { //todo: corriger l
    //     const intervalId = setInterval(() => {
    //         setLoadingText(prev => {
    //             if (prev.length === 3) return '.';
    //             return prev + '.';
    //         });
    //     }, 500); // change le texte toutes les 500 ms
    //
    //     return () => clearInterval(intervalId); // Nettoie l'intervalle lors du démontage
    // }, []);
    return (
        <div className="valve-details">
            <div className={"fixed-header"}>
                <Header infoPopup={infoPopup}
                        titre={id
                            ? decodeURIComponent(sensor_name)
                            : <span>{translations["Chargement en cours"]}<LoaderIcon/></span>}/>
                <div className="header-group req-tabs">

                    <button onClick={() => changementDOngletFrequence(0)}
                            className={ongletFrequence === 0 ? "action-button active" : "action-button req-button"}>
                        <img className={"icon-periode marg"} src={DAY} alt={translations["icon de jour marg"]}/>
                    </button>
                    <button onClick={() => changementDOngletFrequence(1)}
                            className={ongletFrequence === 1 ? "action-button active" : "action-button req-button"}>
                        <img className={"icon-periode marg"} src={WEEK} alt={translations["icon de semaine marg"]}/>
                    </button>
                    <button onClick={() => changementDOngletFrequence(2)}
                            className={ongletFrequence === 2 ? "action-button active" : "action-button req-button"}>
                        <img className={"icon-periode marg"} src={MOIS} alt={translations["icon de mois marg"]}/>
                    </button>
                    <button onClick={() => changementDOngletFrequence(3)}
                            className={ongletFrequence === 3 ? "action-button active" : "action-button req-button"}>
                        <img className={"icon-periode marg"} src={YEAR} alt={translations["icon de années marg"]}/>
                    </button>
                </div>
            </div>
            {loading ? <span>{translations["Chargement en cours"]}<LoaderIcon/></span> : renderTabContent()}
            <div className="action-buttons">
                <div className="action-buttons-group">
                    <div className="header-group tabs">
                        {ongletGeneral.map(tab => (
                            <button
                                key={tab.name}
                                onClick={() => changementDOngletGeneral(tab.name)}
                                className={tab.name === activeTab ? 'tool-button active' : 'tool-button'}
                            >
                                <span className={"tab-icon"}>{tab.icon}</span>
                                {/*<span className={"tab-name"}>{tab.name}</span>*/}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CapteurDetails;
