import {Navigate, Route, Routes, useNavigate, useParams} from 'react-router-dom';
import Auth from './views/Auth/Auth';
import Dashboard from './views/Dashboard';
import Map from './views/Map/Map';
import CapteurDetails from "./CapteurDetails/CapteurDetails";
import {auth, dashboard, map, objDetail} from "./references/link";
import {LanguageProvider} from "./LanguageContext";
import liste_des_langues from "./ressources/liste_des_langues";
import {useEffect, useState} from "react";
import sendPostRequest from "./ressources/tools/sendPostRequest";
import {liste_des_capteurs_en_fontion_de_la_proximite} from "./references/glossaire_requetes";


const RedirectWithId = ({to}) => {
    const {id} = useParams();
    return <Navigate replace to={to.replace(':id', id)}/>;
};

function App() {
    const [loading, setLoading] = useState(false);
    const [login_name, setLogin_name] = useState(null);
    const [login_password, setLogin_password] = useState(null);
    const [iframeLogin, setIframeLogin] = useState(false);
    const [iframeLoginMessage, setIframeLoginMessage] = useState(0);
    const [originPath, setOriginPath] = useState(window.location.pathname);
    useEffect(() => {
        const receiveMessage = (event) => {
            // console.log(`Received message ${iframeLoginMessage}:`, event.data);
            setIframeLoginMessage(iframeLoginMessage + 1)
            if (event.data.type === 'auth') {
                setLogin_name(event.data.login_name);
                setLogin_password(event.data.login_password);
                setIframeLogin(true);
            }
        };
        // window.addEventListener('message', receiveMessage);
        // window.removeEventListener('message', receiveMessage);
        // if (iframeLogin || iframeLoginMessage > 5) {
        if (iframeLogin) {
            window.removeEventListener('message', receiveMessage);
        } else {
            window.addEventListener('message', receiveMessage);
        }

        return () => {
            window.removeEventListener('message', receiveMessage);
        };
    }, [iframeLogin]); // Le tableau de dépendances est vide car nous n'utilisons pas de variables externes

    useEffect(() => {
        if (login_name && login_password) {
            console.log(login_name, login_password)
            reqConnexion(login_name, login_password)
        }
    }, [login_name, login_password]); // Le tableau de dépendances est vide car nous n'utilisons pas de variables externes

    const reqConnexion = (box_login_name, box_login_password) => {
        let sendLogin_name = box_login_name
        let sendLogin_password = box_login_password
        setLoading(true)
        if (login_name && login_password) {
            sendLogin_name = login_name
            sendLogin_password = login_password
        }

// Vérifie si l'application est chargée dans un iframe
        const isInIframe = (window.self !== window.top);

        if (isInIframe) {
            console.log("Connexion iframe");
            sendConnexion(null, null, sendLogin_name, sendLogin_password);
        } else {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const {latitude, longitude} = position.coords;
                    sendConnexion(latitude, longitude, sendLogin_name, sendLogin_password);
                },
                (error) => {
                    console.warn("Erreur de géolocalisation :", error);
                    // Tu peux choisir de gérer l'erreur différemment ici, par exemple en envoyant des valeurs nulles ou des valeurs par défaut
                    sendConnexion(null, null, sendLogin_name, sendLogin_password);
                },
                {
                    enableHighAccuracy: true, // Demande une localisation plus précise
                    timeout: 10000,          // Définit un temps maximal de réponse de 10 secondes
                    maximumAge: 0            // N'accepte pas de cache de position
                }
            );
        }
    }
    const sendConnexion = (latitude, longitude, sendLogin_name, sendLogin_password) => {

        console.log(window.location.pathname)
        sendPostRequest({
            request_type: liste_des_capteurs_en_fontion_de_la_proximite,
            coordinates: {x: latitude, y: longitude},
            login_name: sendLogin_name,
            login_password: sendLogin_password,
        }).then(res => {
            setLoading(false)
            if (res && res["state"] === true) {
                setIsAuth(true)
                // Mettre à jour le localStorage chaque fois que la langue change
                localStorage.setItem(auth, JSON.stringify({
                    login_name: sendLogin_name,
                    login_password: sendLogin_password
                }));
                setData(res["machines_list"])
                //Afficher le lien de l'url vers la machine si ce n'est pas la page /auth
                console.log(originPath, `/${defaultLang}/${auth}`, originPath !== `/${defaultLang}/${auth}`)
                console.log(window.location.pathname === `/${defaultLang}/${auth}`, originPath === `/`, window.location.pathname === `/${defaultLang}/${auth}` || originPath === `/`)
                console.log(originPath !== `/${defaultLang}/${auth}`, originPath !== '/', originPath !== `/${defaultLang}/${auth}` && originPath !== '/')

                // navigate(originPath);
                if (originPath === `/${defaultLang}/${auth}` || originPath === `/`) {
                    navigate(`/${defaultLang}/${dashboard}`);
                } else if (originPath !== `/${defaultLang}/${auth}` && originPath !== '/') {
                    navigate(originPath);
                }
            } else {
                //todo: afficher un message d'erreur lors de la connexion
                alert('Connexion échouée. Veuillez vérifier vos identifiants.');
                navigate(`/${defaultLang}/${auth}`);
            }
        })
    }
    // Définir votre langue par défaut
    let defaultLang = 'fr';
    let initialLang = window.location.pathname.split("/")[1];
    const lang = initialLang
    const navigate = useNavigate();
    // Vérifier si la langue est dans la liste des langues disponibles
    if (liste_des_langues.indexOf(initialLang) < 0) {
        // Récupérer la langue du localStorage ou utiliser la langue par défaut
        initialLang = localStorage.getItem('language') || defaultLang;
    }

    useEffect(() => {
        // Mettre à jour le localStorage chaque fois que la langue change
        localStorage.setItem('language', initialLang);

        // Naviguer vers la nouvelle langue si elle change
        if (window.location.pathname.split("/")[1] !== initialLang) {
            navigate(`/${initialLang}`);
        }
    }, [initialLang, navigate]);

    if (liste_des_langues.indexOf(lang) > -1) {
        defaultLang = initialLang;
    }
    const [data, setData] = useState([]);
    const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {
        let initialAuth = JSON.parse(localStorage.getItem(auth));
        if (!isAuth && initialAuth && initialAuth.login_name && initialAuth.login_password) {
            console.log(initialAuth.login_name, initialAuth.login_password)
            reqConnexion(initialAuth.login_name, initialAuth.login_password)
        } else {
            navigate(`/${defaultLang}/${auth}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LanguageProvider navigate={navigate} currentLanguage={defaultLang}>
            <Routes>
                <Route path={`/:lang/${auth}`} element={<Auth reqConnexion={reqConnexion}
                                                              isAuth={isAuth}
                                                              loading={loading}
                                                              setIsAuth={setIsAuth}/>}/>
                <Route path={`/:lang/${dashboard}`} element={
                    <Dashboard data={data}
                               defaultLang={defaultLang}
                               loading={loading}/>
                }/>
                <Route path={`/:lang/${objDetail}/:id`} element={<CapteurDetails/>}/>
                <Route path={`/:lang/${map}`} element={<Map/>}/>
                <Route path={`/:lang`} element={<Auth reqConnexion={reqConnexion}
                                                      isAuth={isAuth}
                                                      loading={loading}
                                                      setIsAuth={setIsAuth}/>}/>
                {/* Redirection vers la langue par défaut si aucune langue n'est spécifiée */}
                <Route path="/" element={<Navigate replace to={`/${defaultLang}/${auth}`}/>}/>
                <Route path={`/${auth}`} element={<Navigate replace to={`/${defaultLang}/${auth}`}/>}/>
                <Route path={`/${dashboard}`} element={<Navigate replace to={`/${defaultLang}/${dashboard}`}/>}/>
                <Route path={`/${objDetail}/:id`}
                       element={<RedirectWithId to={`/${defaultLang}/${objDetail}/:id`}/>}/>
                <Route path={`/${map}`} element={<Navigate replace to={`/${defaultLang}/${map}`}/>}/>
            </Routes>
        </LanguageProvider>
    );
}

export default App;
