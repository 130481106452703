export const getStartAndEndOfDay = (dateInput) => {
    const date = new Date(dateInput);

    // Début de la journée à minuit
    const start_interval = new Date(date.setHours(0, 0, 0, 0)).getTime() / 1000;

    // Fin de la journée: début de la journée suivante à minuit
    const end_interval = start_interval + 86400; // Ajoute 24 heures en secondes

    return { start_interval, end_interval };
};

export const getStartOfWeek = (dateInput) => {
    const date = new Date(dateInput);
    date.setHours(0, 0, 0, 0); // Ajuste la date pour minuit

    // Calcule le décalage jusqu'au lundi
    const day = date.getDay(); // Dimanche - 0, Lundi - 1, etc.
    const diff = day === 0 ? -6 : 1 - day; // Si c'est dimanche, décale de -6 jours, sinon décale pour revenir à lundi

    // Ajuste la date au lundi de la semaine
    date.setDate(date.getDate() + diff);
    const start_interval = date.getTime() / 1000; // Convertit en timestamp UNIX (secondes)
    const end_interval = start_interval + 86400 * 6; // Ajoute 6 jours pour arriver au dimanche suivant à minuit

    return { start_interval, end_interval };
};
export const getStartAndEndOfMonth = (dateInput) => {
    const date = new Date(dateInput);
    date.setHours(0, 0, 0, 0); // Ajuste la date pour minuit

    // Trouve le premier jour du mois
    const start_interval = new Date(date.getFullYear(), date.getMonth(), 1).getTime() / 1000;

    // Trouve le dernier jour du mois
    const end_interval = new Date(date.getFullYear(), date.getMonth() + 1, 1).getTime() / 1000;

    return { start_interval, end_interval };
};
export const getStartAndEndOfYear = (dateInput) => {
    const date = new Date(dateInput);
    date.setHours(0, 0, 0, 0); // Ajuste la date pour minuit

    // Trouve le premier jour de l'année
    const start_interval = new Date(date.getFullYear(), 0, 1).getTime() / 1000;

    // Trouve le dernier jour de l'année
    const end_interval = new Date(date.getFullYear() + 1, 0, 0).getTime() / 1000;

    return { start_interval, end_interval };
};
