// Fonction pour envoyer la requête POST

const sendPostRequest = async (data) => {

    console.log('Envoi: ', data);
    let cheminURLAPI;
    switch (document.domain) {
        case "localhost":
            cheminURLAPI = "http://localhost/carboparc/api_argos/api.php";
            break;
        case "carboparc.itmsol.fr":
            cheminURLAPI = "https://carboparc.itmsol.fr/api_carbo";
            break;
        default:
            cheminURLAPI = "./index.php";
    }


    try {
        const response = await fetch(cheminURLAPI, {
            method: 'POST', // Méthode HTTP
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(data) // convertit l'objet data en chaîne JSON
        });
        let res;
        if (!response.ok) {
            throw new Error(`Erreur: ${response.status}`);
        }
        if (response.headers.get('Content-Type').includes('application/json')) {
            res = await response.json();
            console.log('Réponse reçue (JSON):', res);
        } else {
            res = await response.text();
            console.log('Réponse reçue (texte):', res);
        }
        return res
    } catch (error) {
        console.error("Erreur lors de l'envoi de la requête:", error);
    }
};

export default sendPostRequest;