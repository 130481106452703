import L from 'leaflet';

export function createIcon(letter) {
    return L.divIcon({
        className: letter === 'U' ? 'user-marker-icon' : 'valve-marker-icon',
        html: letter,
        iconSize: [20, 20],
        iconAnchor: [10, 10],
        popupAnchor: [0, -10]
    });
}
