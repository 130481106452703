import React, {useEffect, useState} from 'react';
import fond from "../../CapteurDetails/Objectif/feuille_transparente.png"
import surface from "../../CapteurDetails/Objectif/feuille_verte.png"

const ImageSlider = ({value, maxValue}) => {
    const [sliderValue, setSliderValue] = useState(100);
    useEffect(() => {
        setSliderValue(100 - value / maxValue * 100)
    }, [value, maxValue]);

    // Adapter le canvas à la taille de l'écran
    const vh = Math.max(window.innerHeight);


    // Calculer la valeur pour le clip-path basé sur la valeur du slider
    const clipPathValue = `polygon(0 ${100 - sliderValue}%, 100% ${100 - sliderValue}%, 100% 100%, 0 100%)`;

    return (
        <div style={{position: 'relative', width: 0.35 * vh, height: 0.45 * vh, overflow: 'hidden'}}>
            <img src={fond} alt="Image modifiable" style={{width: '100%', position: 'absolute', top: 0, left: 0}}/>
            <img src={surface} alt="Image modifiable" style={{
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                clipPath: clipPathValue,
                transition: 'clip-path 0.5s ease'
            }}/>
        </div>
    );
};

export default ImageSlider;
