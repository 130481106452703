import React, { useEffect, useState } from "react";
import nuageCO2Rouge from "../../ressources/images/nuage CO2 rouge.svg";
import nuageCO2Vert from "../../ressources/images/nuage CO2 vert.svg";
import PopupOn from "../../ressources/images/PopupOn.png";
import headIndoredCo2 from "../../ressources/images/red.svg";
import HistogramRelatif from "../../views/Graph/HistogramRelatif";
import { carburant2CO2 } from "../../references/glossaire_carburant2CO2";
import "./CO2.css";
import {AiOutlineClose} from "react-icons/ai";
const CO2 = ({ mediane, data, indexSensor, engineeringUnitCO2, carburant, carburantSelector }) => {
    const litreParH =
        indexSensor !== undefined && data.length > 0 && data[indexSensor] !== undefined
            ? data[indexSensor].debit
            : null;
    const [c02ParH, setC02ParH] = useState(null);
    const [hasValidData, setHasValidData] = useState(false);
    const [adjustedData, setAdjustedData] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const isValid = (value) => value != null && Number.isFinite(value);

    useEffect(() => {
        if (isValid(litreParH) && carburant in carburant2CO2) {
            setC02ParH(litreParH * carburant2CO2[carburant]);
        } else {
            setC02ParH(null);
        }
    }, [carburant, litreParH]);

    // Calcular a mediana ajustada
    const adjustedMediane = mediane * carburant2CO2[carburant];

    useEffect(() => {
        if (
            !isValid(adjustedMediane) ||
            !Array.isArray(data) ||
            !isValid(indexSensor) ||
            !isValid(litreParH) ||
            !isValid(c02ParH)
        ) {
            setHasValidData(false);
        } else {
            setHasValidData(true);
        }
    }, [adjustedMediane, data, indexSensor, litreParH, c02ParH]);

    // Recalcula os dados ajustados quando o carburant muda
    useEffect(() => {
        if (data && data.length > 0 && carburant in carburant2CO2) {
            const newData = data.map(item => {
                const adjustedDebit = item.debit * carburant2CO2[carburant];
                return { ...item, adjustedDebit };
            });
            setAdjustedData(newData);
        } else {
            setAdjustedData([]);
        }
    }, [data, carburant]);

    if (!hasValidData) {
        return (
            <button className="btnMsg">
                Cet engin est à l'arrêt, pas de données pour cette période sélectionnée
            </button>
        );
    }
    const handleClick = () => {
        setShowPopup(true);
    };

    return (
        <div className="tab-content">
            {/* {c02ParH > adjustedMediane ? (
                <img src={headIndoredCo2} className={"headBandInfo"} alt={"CO2 msg"} />
            ) : (
                <img src={headIndoCo2} className={"headBandInfo"} alt={"CO2 msg"} />
            )} */}
    
            <div className={"config-group"}>
                <div className="input-group">
                    <h3 className={"header2"}>
                        <div className="marginDiv">
                            {c02ParH != null ? (
                                <>
                                    {c02ParH > adjustedMediane ? "+" : "-"}
                                    {c02ParH.toFixed(2)}
                                </>
                            ) : (
                                ""
                            )}
                            <span className="unitcss">{engineeringUnitCO2}</span>
                        </div>
                        <div className="CarburanSelct">{carburantSelector}</div>
                    </h3>
                    {c02ParH > adjustedMediane ? (
                        <img src={nuageCO2Rouge} className={"icon-label nuague"} alt={"CO2 elevado"} />
                    ) : (
                        <img src={nuageCO2Vert} className={"icon-label nuague"} alt={"CO2 baixo"} />
                    )}
                    <div className="input-group padd">
                        {/* {Array.isArray(adjustedData) && adjustedData.length > 0 && isValid(indexSensor) ? ( */}
                            <HistogramRelatif
                                data={adjustedData}
                                mediane={adjustedMediane}
                                indexSensor={litreParH && indexSensor}
                                unit={engineeringUnitCO2}
                            />
                        {/* ) : null} */}
                    </div>
                    <button onClick={handleClick} className="conseil marginup">
                        Afficher la légende
                    </button>
                      {/* Renderiza a popup se showPopup for true */}
                      {showPopup && (
                        <div className="backBluer">
                            <div className="popup nwspopup">
                                <div className="popup-content">

                                    <span className="Nclose" onClick={() => setShowPopup(false)}> <AiOutlineClose className={"close-icon"}/></span>
                                    <img src={PopupOn} className={"npopup"} alt={"Legende"} />
                                </div>
                            </div>
                        </div>
                        )}
                </div>
            </div>
        </div>
    );
    
};

export default CO2;
