import React, { useEffect, useRef } from 'react';

const SemiCircleGauge = ({ value, unite, min, max, color, backgroundColor, textColor }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const vw = Math.min(window.innerWidth);
        const vh = Math.max(window.innerHeight);

        const width = 0.03 * vw + 0.30 * vh;
        const height = 0.03 * vw + 0.22 * vh;

        // Definir as dimensões do canvas
        canvas.width = width;
        canvas.height = height;

        // Configurações
        const epaisseurGauge = 0.01 * vw + 0.04 * vh;
        const fontSize = 0.01 * vw + 0.04 * vh;
        const paddingBottom = 0.01 * vw + 0.04 * vh;

        // Limpar o canvas antes de desenhar
        ctx.clearRect(0, 0, width, height);

        // Desenhar o arco de fundo
        ctx.beginPath();
        ctx.arc(
            width / 2,
            height - paddingBottom,
            width / 2 - epaisseurGauge / 2,
            Math.PI,
            0,
            false
        );
        ctx.lineWidth = epaisseurGauge;
        ctx.strokeStyle = backgroundColor;
        ctx.stroke();

        // Desenhar o arco de progresso
        const angle = Math.PI + (Math.PI * (value - min)) / (max - min);
        ctx.beginPath();
        ctx.arc(
            width / 2,
            height - paddingBottom,
            width / 2 - epaisseurGauge / 2,
            Math.PI,
            angle,
            false
        );
        ctx.lineWidth = epaisseurGauge;
        ctx.strokeStyle = color;
        ctx.stroke();

        // Adicionar o texto do valor
        ctx.fillStyle = textColor;
        ctx.font = 'bold ' + fontSize + 'px Montserrat';
        ctx.textAlign = 'center';

        ctx.fillText(
            `${value}`,
            width / 2,
            height - paddingBottom + epaisseurGauge / 5 - fontSize / 1.5
        );

        // Adicionar o texto da unidade
        ctx.font = 'bold ' + fontSize / 2 + 'px Montserrat';
        ctx.fillText(
            `${unite}`,
            width / 2,
            height - paddingBottom + epaisseurGauge / 5 + fontSize / 3
        );

        // Ajustar levemente as posições das labels 'min' e 'max'
        ctx.font = 'bold ' + fontSize / 2 + 'px Montserrat';
        ctx.textAlign = 'center';

        const radius = width / 2 - epaisseurGauge / 2;
        const centerX = width / 2;
        const centerY = height - paddingBottom;
        const labelOffsetY = paddingBottom / 2; // Ajustar para posicionar as labels verticalmente

        // Valor de ajuste menor para mover as labels
        const adjustment = -3; // Modifique este valor para mover 'min' e 'max' levemente

        // Definir a cor específica para 'min' e 'max'
        const labelColor = '#4194D0';

        // Posição da label 'min' (à esquerda)
        const minX = centerX - radius - adjustment;
        const minY = centerY + labelOffsetY;
        ctx.fillStyle = labelColor;
        ctx.fillText('min', minX, minY);

        // Posição da label 'max' (à direita)
        const maxX = centerX + radius + adjustment;
        const maxY = centerY + labelOffsetY;
        ctx.fillText('max', maxX, maxY);

    }, [value, min, max, color, backgroundColor, textColor, unite]);

    return (
        <div>
            <canvas ref={canvasRef} />
        </div>
    );
};

SemiCircleGauge.defaultProps = {
    value: 50,
    min: 0,
    max: 100,
    color: '#0099ff',
    backgroundColor: '#e6e6e6',
    textColor: '#000'
};

export default SemiCircleGauge;
