import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import { createIcon } from './helpers'; // Assuming you moved createIcon to a helpers file
import {Link} from "react-router-dom";
import {objDetail} from "../../references/link";

const ValveMarker = ({ valve }) => {
    return (
        <Marker position={[valve.lat, valve.lng]} icon={createIcon('V')}>
            <Popup><Link to={`/${objDetail}/${valve.id}`}>{valve.name}</Link></Popup>
        </Marker>
    );
};

export default ValveMarker;
