import React from 'react';
import ValveMarker from './ValveMarker';

// Assume valves are fetched from a state or props
const valves = [
    {id: 41621, lat: 43.595920522723, lng: 1.4525610182642, name: "41621"},
    {id: 41816, lat: 43.595578236636, lng: 1.4525705502823, name: "41816"},
    {id: 41816, lat: 43.595981892252, lng: 1.4529815762143, name: "41816"},
    {id: 41816, lat: 43.595920522411, lng: 1.4534536663508, name: "41816"},
    {id: 41816, lat: 43.595991230616, lng: 1.4536650152393, name: "41816"},
    {id: 41816, lat: 43.595814849939, lng: 1.4521651425774, name: "41816"},
    {id: 41816, lat: 43.595562323149, lng: 1.4521039670057, name: "41816"},
    {id: 41816, lat: 43.595382057417, lng: 1.4517273942974, name: "41816"},
    {id: 41816, lat: 43.595684304489, lng: 1.4530063089379, name: "41816"},
    {id: 41816, lat: 43.59558795573, lng: 1.4537003590793, name: "41816"},
    {id: 41816, lat: 43.595335417589, lng: 1.4533463676426, name: "41816"},
    {id: 41816, lat: 43.595127972182, lng: 1.4532863117741, name: "41816"},
    {id: 41816, lat: 43.595043278813, lng: 1.4530212818341, name: "41816"},
    {id: 41816, lat: 43.595143513281, lng: 1.4527541347264, name: "41816"},
    {id: 41816, lat: 43.59507435985, lng: 1.4523829212623, name: "41816"},
    {id: 41816, lat: 43.595389049594, lng: 1.4523721904941, name: "41816"},
    {id: 41816, lat: 43.595682758347, lng: 1.4516018609034, name: "41816"},
    {id: 41816, lat: 43.595800864364, lng: 1.4514162790742, name: "41816"},
    {id: 41816, lat: 43.59596324965, lng: 1.4515214460434, name: "41816"},
    {id: 41816, lat: 43.596075902745, lng: 1.4514152599325, name: "41816"},
    {id: 41816, lat: 43.596521888151, lng: 1.4521093540511, name: "41816"},
    {id: 41816, lat: 43.596541301578, lng: 1.4519548028104, name: "41816"},
    {id: 41816, lat: 43.596380478794, lng: 1.4518024913034, name: "41816"},
    {id: 41816, lat: 43.596330710288, lng: 1.4521146127338, name: "41816"},
    {id: 41816, lat: 43.59633306598, lng: 1.4527649230611, name: "41816"},
    {id: 41816, lat: 43.596479924417, lng: 1.4528281918956, name: "41816"},
    {id: 41816, lat: 43.596346546748, lng: 1.4506700325673, name: "41816"},
    {id: 41816, lat: 43.596249847635, lng: 1.4509006613079, name: "41816"},
    {id: 41816, lat: 43.595903713389, lng: 1.4509916911839, name: "41816"},
    {id: 41621, lat: 43.59573778551, lng: 1.4509962523702, name: "41621"},
    {id: 41816, lat: 43.596199179421, lng: 1.4502671350365, name: "41816"},
    {id: 41816, lat: 43.596131173049, lng: 1.4503483698293, name: "41816"},
    {id: 41816, lat: 43.596054254201, lng: 1.4506351471376, name: "41816"},
    {id: 41816, lat: 43.595892724479, lng: 1.4507534869858, name: "41816"},
    {id: 41816, lat: 43.595838877842, lng: 1.4501177406257, name: "41816"},
    {id: 41816, lat: 43.595696026885, lng: 1.4506761191813, name: "41816"},
    {id: 41816, lat: 43.595588338643, lng: 1.4504045074217, name: "41816"},
    {id: 41816, lat: 43.595489442017, lng: 1.4500767795572, name: "41816"},
    {id: 41816, lat: 43.595190545482, lng: 1.4500479638384, name: "41816"},
    {id: 41816, lat: 43.595337799533, lng: 1.4504060250696, name: "41816"},
    {id: 41816, lat: 43.595532297873, lng: 1.4496276550614, name: "41816"},
    {id: 41816, lat: 43.595398238577, lng: 1.4493894504003, name: "41816"},
    {id: 41816, lat: 43.595303636647, lng: 1.4495313397782, name: "41816"},
    {id: 41816, lat: 43.595609218604, lng: 1.4494091702814, name: "41816"},
    {id: 41816, lat: 43.595091655964, lng: 1.4497020085947, name: "41816"},
    {id: 41816, lat: 43.594854299326, lng: 1.4490495758427, name: "41816"},
    {id: 41816, lat: 43.594935526243, lng: 1.4489548404006, name: "41816"},
    {id: 41816, lat: 43.595078468247, lng: 1.4486914912721, name: "41816"},
    {id: 41816, lat: 43.594580680039, lng: 1.451926351496, name: "41816"},
    {id: 41816, lat: 43.594431233445, lng: 1.4520826294357, name: "41816"},
    {id: 41816, lat: 43.594327936516, lng: 1.4514408234596, name: "41816"},
    {id: 41816, lat: 43.593985021801, lng: 1.452148343532, name: "41816"},
    {id: 41816, lat: 43.593635641091, lng: 1.4520871888156, name: "41816"},
    {id: 41816, lat: 43.593788385634, lng: 1.4520401406701, name: "41816"},
    {id: 41816, lat: 43.593738936712, lng: 1.4523360115467, name: "41816"},
    {id: 41816, lat: 43.593414760627, lng: 1.4514939216348, name: "41816"},
    {id: 41816, lat: 43.593252125118, lng: 1.4515515727536, name: "41816"},
    {id: 41816, lat: 43.592911466228, lng: 1.4517958603703, name: "41816"},
    {id: 41816, lat: 43.593030147331, lng: 1.451339159954, name: "41816"},
    {id: 41816, lat: 43.592966410353, lng: 1.4515682715098, name: "41816"},
    {id: 41816, lat: 43.592490556713, lng: 1.4503202336634, name: "41816"},
    {id: 41816, lat: 43.592698276296, lng: 1.4505972003402, name: "41816"},
    {id: 41816, lat: 43.59301256379, lng: 1.450780796495, name: "41816"},
    {id: 41816, lat: 43.592390582301, lng: 1.4506761077431, name: "41816"},
    {id: 41816, lat: 43.592413634711, lng: 1.4517994096982, name: "41816"},
    {id: 41816, lat: 43.591552114967, lng: 1.4520067514007, name: "41816"},
    {id: 41816, lat: 43.591544988675, lng: 1.452384254341, name: "41816"},
    {id: 41816, lat: 43.591991674228, lng: 1.4523936750297, name: "41816"},
    {id: 41816, lat: 43.592182868117, lng: 1.4520497317964, name: "41816"},
    {id: 41816, lat: 43.592380668109, lng: 1.4493720438976, name: "41816"},
    {id: 41816, lat: 43.592737805337, lng: 1.4495616818507, name: "41816"},
    {id: 41621, lat: 43.592904870914, lng: 1.449491106589, name: "41621"},
    {id: 41621, lat: 43.611475144478, lng: 1.4335581083957, name: "41621"},
    {id: 41816, lat: 43.612164577202, lng: 1.4319431329329, name: "41816"},
    {id: 41816, lat: 43.611586832061, lng: 1.4322459031432, name: "41816"},
    {id: 41816, lat: 43.612015894366, lng: 1.4326684929571, name: "41816"},
    {id: 41621, lat: 43.572475321962, lng: 1.3999132059094, name: "41621"},
    {id: 41816, lat: 43.571666974674, lng: 1.3981658581953, name: "41816"},
    {id: 41816, lat: 43.573127245679, lng: 1.397357797581, name: "41816"},
    {id: 41816, lat: 43.569636389064, lng: 1.3981307935648, name: "41816"},
    {id: 41816, lat: 43.56990084865, lng: 1.400754815227, name: "41816"},
];

const ValvesCluster = () => {
    return (
        valves.map((valve) => (
            <ValveMarker key={valve.id} valve={valve}/>
        ))
    );
};

export default ValvesCluster;
