import React, { useEffect, useRef } from 'react';
import "./HistogramRelatif.css";

const HistogramRelatif = ({ data, mediane, indexSensor, unit }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');

        // Obter as dimensões da janela
        const vw = Math.min(window.innerWidth);
        const vh = Math.max(window.innerHeight);

        const width = 0.8 * vw + 0.08 * vh;
        const height = 0.005 * vw + 0.5 * vh;

        // Aumentar a altura do canvas para dar mais espaço para a 'unit'
        const extraTopSpace = 35; // Adicione espaço extra no topo

        // Definir as dimensões do canvas
        canvas.width = width;
        canvas.height = height + extraTopSpace; // Aumenta a altura do canvas

        /* Parâmetros */
        const marginHisto = 0.03 * vw + extraTopSpace; // Ajustar margem para considerar o espaço extra
        const y_start = marginHisto / 2 + 0.02 * vh;
        const arrowSize = 0.002 * vw + 0.01 * vh;
        const epaisseurAxe = 0.001 * vw + 0.002 * vh;
        const padding_histo = 0.005 * vw + 0.005 * vh;
        const fontSize = 0.013 * vw + 0.013 * vh; // Define o tamanho da fonte

        if (!data || data.length === 0) {
            ctx.clearRect(0, 0, width, canvas.height);
            return;
        }

        // Usar adjustedDebit e mediane ajustada
        const dataValues = data.map(v => v.adjustedDebit - mediane);
        const abs_max_value = Math.max(...dataValues.map(Math.abs));

        // Cálculo do espaçamento inicial dinâmico
        const initialSpacing = 0.05 * width; // 5% da largura total

        // Calcular a largura das barras considerando o espaçamento inicial
        const barAreaWidth = width - marginHisto - initialSpacing - arrowSize;
        const barWidth = barAreaWidth / dataValues.length;

        ctx.clearRect(0, 0, width, canvas.height);

        // Definir a espessura da linha
        ctx.lineWidth = epaisseurAxe;

        // Desenhar as barras do histograma
        dataValues.forEach((value, index) => {
            const x = marginHisto + barWidth * index + initialSpacing;
            const barHeight = (value * ((height) / 2 - marginHisto / 2)) / abs_max_value;
            const y = (height + extraTopSpace) / 2 - barHeight;

            ctx.fillStyle = index === indexSensor
                ? value > 0
                    ? "#a5163f" // vermelho
                    : "#49b757" // verde
                : '#d9d9d9'; // cinza claro #efefef

            ctx.fillRect(x, y, barWidth - padding_histo, barHeight);
        });

        ctx.fillStyle = '#000000';

        // Desenhar os eixos ajustados
        ctx.beginPath();
        ctx.moveTo(y_start + initialSpacing, canvas.height);
        ctx.lineTo(y_start + initialSpacing, extraTopSpace); // Mover o eixo Y até o topo do canvas
        ctx.moveTo(y_start + initialSpacing, (height + extraTopSpace) / 2);
        ctx.lineTo(width - arrowSize, (height + extraTopSpace) / 2); // Eixo X
        ctx.stroke();

        // Flecha para o eixo Y (movida para o topo do eixo Y)
        ctx.beginPath();
        ctx.moveTo(y_start + initialSpacing - arrowSize / 1.5, extraTopSpace + arrowSize);
        ctx.lineTo(y_start + initialSpacing, extraTopSpace);
        ctx.lineTo(y_start + initialSpacing + arrowSize / 1.5, extraTopSpace + arrowSize);
        ctx.fill();

        // Flecha para o eixo X
        // ctx.beginPath();
        // ctx.moveTo(width - arrowSize * , (height + extraTopSpace) / 2 + arrowSize);
        // ctx.lineTo(width - arrowSize, (height + extraTopSpace) / 2);
        // ctx.lineTo(width - arrowSize * 0, (height + extraTopSpace) / 2 - arrowSize);
        // ctx.fill();

        // Adicionar a palavra 'Moyenne' ao final do eixo X
        ctx.fillStyle = '#000000';
        ctx.font = 'bold ' + fontSize + 'px Montserrat';
        ctx.textAlign = 'right';
        ctx.fillText('Moyenne', width - arrowSize - 10, (height + extraTopSpace) / 2 - 10);

        // Adicionar a unidade no final da flecha do eixo Y (horizontal)
        ctx.textAlign = 'center';
        ctx.fillText(unit, y_start + initialSpacing, extraTopSpace - 10); // Ajuste a posição conforme necessário

    }, [data, mediane, indexSensor, unit]);

    return <canvas className="histoc" ref={canvasRef} />;
};

export default HistogramRelatif;
