/**
 * Glossaire des types de requêtes
 *
 * cf. version API v1/v2/v3/v4
 *
 */
//AUTHENTIFICATION
export const connexion = 10100;
export const deconnexion = 10101;
export const changer_son_mot_de_passe = 10102;
export const demande_de_reinitialisation_du_mot_de_passe = 10103;
export const information_de_connexion = 10104;
export const validation_du_code_de_verification = 10105;
export const reinitialisation_du_mot_de_passe = 10106;

//UTILISATEUR
export const creation_d_un_utilisateur = 10200;
export const modification_d_un_utilisateur = 10201;
export const modification_de_la_liste_des_projets_d_un_utilisateur = 10202;
export const recuperation_de_la_liste_des_projets_d_un_utilisateurs = 10203;
export const recuperation_de_la_liste_des_utilisateurs = 10204;
export const recuperation_d_un_utilisateur = 10205;
export const modifier_mot_de_passe_du_compte_utilisateur = 10206;
export const supprimer_un_utilisateur = 10207;
export const ajouter_une_image_de_profil = 10208;
export const liste_des_privileges_associes_a_une_categorie_d_utilisateur = 10209;

//PROJET
export const creation_d_un_projet = 10300;
export const modification_d_un_projet = 10301;
export const supprimer_un_projet = 10302;
export const recuperation_d_un_projet = 10303;
export const ajouter_une_image_de_projet = 10304;
export const recuperation_de_la_liste_des_projets = 10305;
export const rajouter_un_projet_en_favori = 10306;
export const supprimer_un_projet_des_favoris = 10307;
export const recuperation_de_la_liste_des_projets_des_favoris = 10308;

//CAPTEUR
export const creation_d_un_capteur = 10400;
export const modification_d_un_capteur = 10401;
export const supprimer_un_capteur = 10402;
export const recuperation_d_un_capteur = 10403;
export const recuperation_de_la_liste_des_capteurs = 10404;
export const creation_d_un_sensor_type = 10405;
export const modification_d_un_sensor_type = 10406;
export const suppression_d_un_sensor_type = 10407;
export const recuperation_de_la_liste_des_sensor_type = 10408;
export const importation_des_capteurs_en_lot = 10409;
export const recuperation_de_la_liste_des_filtres_d_importation = 10410;
export const recuperation_de_la_liste_des_groupes_d_emails = 10411;
export const ajouter_une_image_de_type_de_capteur = 10412;

//GRAPHE
export const recuperation_de_la_liste_des_graphes = 10500;
export const creer_un_graphe = 10501;
export const recuperer_un_graphe = 10502;
export const modifier_un_graphe = 10503;
export const supprimer_un_graphe = 10504;
export const recuperation_des_donnees_d_un_graphe = 10505;
export const recuperation_des_donnees_d_un_graphe_automatique_xt = 10506;
export const recuperation_de_la_liste_des_formats_de_dates = 10507;
export const recuperation_de_la_liste_des_capteurs_reduites = 10508;

// VUE
export const recuperation_de_la_liste_des_vues = 10600;
export const recuperer_une_vue = 10601;
export const creer_une_vue = 10602;
export const ajouter_une_couche_de_vue = 10603;
export const recuperer_la_liste_des_couches_de_vue = 10604;
export const modifier_l_ordre_des_couches = 10605;
export const supprimer_une_couche_de_vue = 10606;
export const modifier_une_vue = 10607;
export const supprimer_une_vue = 10608;
export const recuperer_la_liste_des_objets_associe_a_la_vue = 10609;
export const ajouter_un_objet_associe_a_la_vue = 10610;
export const modifier_un_objet_associe_a_la_vue = 10611;
export const supprimer_un_objet_associe_a_la_vue = 10612;
export const recuperer_la_liste_des_objets_a_afficher_associes_a_une_vue = 10613;
export const recuperer_les_informations_complementaires_d_un_objet = 10614;
export const recuperer_la_liste_des_configurations_de_representation_d_une_map = 10615;
export const recuperer_la_liste_des_tableaux_de_bord_favoris  = 10616;
export const recuperer_la_liste_des_tableaux_de_bord_utilisateur  = 10617;
export const recuperer_la_liste_des_systemes_de_coordonnees_projection = 10618;
export const modifier_la_liste_des_tableaux_de_bord_favoris_utilisateur  = 10619;

// ALARME
export const recuperer_la_liste_des_alarmes = 10700;
export const confirmer_une_alarme = 10701;
export const recuperer_la_liste_des_groupes_d_email = 10702;
export const creer_un_groupe_d_email = 10703;
export const modifier_un_groupe_d_email = 10704;
export const supprimer_un_groupe_d_email = 10705;
export const creer_un_gabarit = 10706;
export const modifier_un_gabarit = 10707;
export const supprimer_un_gabarit = 10708;
export const recuperer_la_liste_des_gabarits = 10709;
export const configurer_des_alarmes_xy = 10710;
export const modifier_des_alarmes_xy = 10711;
export const supprimer_une_configuration_d_alarme_xy = 10712;
export const recuperer_la_liste_des_configurations_des_alarmes_xy = 10713;

// RAPPORT
export const recuperer_la_liste_des_rapports_publies = 10800;
export const recuperer_la_liste_des_rapports_gabarits = 10801;
export const recuperer_les_configurations_d_un_rapport_gabarit = 10802;
export const creer_un_rapport_gabarit = 10803;
export const modifier_un_rapport_gabarit = 10804;
export const supprimer_un_rapport_gabarit = 10805;
export const generer_un_rapport = 10806;
export const recuperer_la_liste_des_rapports_generes = 10807;
export const publier_un_rapport_genere = 10808;
export const charger_le_document_rapport = 10809;
export const supprimer_un_rapport_genere = 10810;

// MAINTENANCE
export const recuperer_objet_projets_taux_de_pannes = 10900;
export const graphe_taux_de_pannes = 10901;
export const liste_des_capteurs_defaillants_par_projet = 10902;

//CARBOPARC
export const liste_des_capteurs_en_fontion_de_la_proximite = 11000;
export const mediane_max_pour_un_type_sur_30j = 11001;
export const recuperer_liste_des_debit_par_type = 11002;
export const recuperer_liste_des_debit_d_un_engin = 11003;
export const recuperer_le_cumule_d_un_engin = 11004;
export const recuperer_les_donnees_de_fonctionnement_et_de_ralenti_par_engin = 11005;
