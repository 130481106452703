import React, { useEffect, useRef } from 'react';
import "./Histogram.css";

const Histogram = ({ unite, dates, values }) => {
    const canvasRef = useRef(null);
    useEffect(() => {
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');

            // Adapter le canvas à la taille de l'écran
            const vw = window.innerWidth;
            const vh = window.innerHeight;

            // Augmenter la taille de l'histogramme
            const width = 0.90 * vw + 0.04 * vh;
            const height = -0.070 * vw + 0.5 * vh;
            // Définir les dimensions du canvas
            canvas.width = width;
            canvas.height = height;

            /* paramètres */
            const marginHisto = 0.09 * vw + 0.02 * vh;
            const y_start = marginHisto / 2 - (0.001 * vw + 0.002 * vh);
            const arrowSize = 0.01 * vw + 0.01 * vh;
            const epaisseurAxe = 0.001 * vw + 0.002 * vh;
            const padding_histo = marginHisto / 2 - (0.001 * vw + 0.002 * vh) - epaisseurAxe;
            const marge_bottom_texte = 0.01 * vw + 0.025 * vh;
            const texte_size = 0.013 * vw + 0.013 * vh;

            const raw = values;

            // Données de l'histogramme
            const moyenneData = raw.reduce((a, b) => a + b, 0) / raw.length;
            const data = raw.map(v => v);
            const abs_max_value = Math.max(Math.abs(moyenneData), ...data.map(Math.abs)) * 1.3;

            const barWidth = (width - marginHisto) / data.length;
            const xaxis_start = height - arrowSize - marge_bottom_texte;
            const yaxis_bottom = xaxis_start + epaisseurAxe / 2;

            ctx.clearRect(0, 0, width, height);
            let angle;
            let si_90_deg = 0;
            if (data.length > 15) {
                angle = 90;
                si_90_deg = 0.004 * vw + 0.004 * vh;
            } else {
                angle = window.innerHeight > window.innerWidth ? 30 : 0;
            }
            // Définir l'épaisseur du trait
            ctx.lineWidth = epaisseurAxe;

            // Dessiner les barres de l'histogramme
            data.forEach((value, index) => {
                const x = marginHisto - arrowSize / 2 + barWidth * index;
                const y = yaxis_bottom - value * yaxis_bottom / abs_max_value;
                ctx.fillStyle = '#4193cf';
                ctx.fillRect(x, y - epaisseurAxe, barWidth - padding_histo, (value * yaxis_bottom) / abs_max_value);

                const x_hour = x + (barWidth - padding_histo) / 2;
                const y_hour = yaxis_bottom + marge_bottom_texte;
                ctx.fillStyle = '#000000';

                ctx.save();
                ctx.textAlign = 'center';
                ctx.translate(x_hour - si_90_deg, y_hour);
                ctx.rotate(angle * Math.PI / 180);
                ctx.font = 'bold ' + texte_size + 'px Montserrat';
                ctx.fillText(dates[index], 0, 0);
                ctx.restore();
            });

            ctx.fillStyle = '#000000';
            // Définir le point supérieur de l'axe Y
            const yAxisTop = arrowSize * 2; // Position où l'axe Y s'arrête (base de la flèche)

            // Dessiner les axes
            ctx.beginPath();
            // Dessiner la ligne de la moyenne
            const moyenneY = yaxis_bottom - moyenneData * yaxis_bottom / abs_max_value - epaisseurAxe / 2;
            ctx.moveTo(y_start, moyenneY);
            ctx.lineTo(width - arrowSize / 2, moyenneY);
            // Dessiner l'axe Y jusqu'à la base de la flèche
            ctx.moveTo(y_start, yaxis_bottom);
            ctx.lineTo(y_start, yAxisTop);
            // Dessiner l'axe X
            ctx.moveTo(y_start, xaxis_start);
            ctx.lineTo(width - arrowSize, xaxis_start);
            ctx.stroke();

            // Dessiner la flèche pour l'axe Y
            ctx.beginPath();
            ctx.moveTo(y_start - arrowSize / 1.5, yAxisTop);
            ctx.lineTo(y_start, yAxisTop - arrowSize);
            ctx.lineTo(y_start + arrowSize / 1.5, yAxisTop);
            ctx.fill();

            // Ajouter le texte 'Unité' près de la flèche de l'axe Y
            ctx.fillStyle = '#000000';
            ctx.font = '900 ' + texte_size + 'px Montserrat';
            ctx.textAlign = 'left';
            ctx.fillText(unite, y_start + arrowSize, yAxisTop - arrowSize);

            // Ajouter le texte 'Moyenne' à la fin de la ligne de la moyenne
            ctx.fillStyle = '#000000';
            ctx.font = 'bold ' + texte_size + 'px Montserrat';
            ctx.textAlign = 'right';
            ctx.fillText('Moyenne', width - arrowSize / 2 - 5, moyenneY - 5);

            // Flèche pour l'axe X
            ctx.beginPath();
            ctx.moveTo(width - arrowSize, xaxis_start + arrowSize);
            ctx.lineTo(width, xaxis_start);
            ctx.lineTo(width - arrowSize, xaxis_start - arrowSize);
            ctx.fill();
        }
    }, [unite, dates, values]);

    return <canvas className={"histo"} ref={canvasRef} width={350} height={350} />;
};

export default Histogram;
