import React, { useContext, useState, useEffect } from 'react';
import "./Conseil.css";
import BarProgression from "../../views/Graph/BarProgression";
import minuteur from "../../ressources/images/minuteur.svg";
import { LanguageContext } from "../../LanguageContext";

const Conseil = ({ seuilRalenti, debitFonctionnement, debitRalenti, tempsTravail, tempsRalenti, maxDebit }) => {
    const { translations } = useContext(LanguageContext);

    // Inicializar os estados com strings vazias
    const [conseilFonctionnement, setConseilFonctionnement] = useState('');
    const [conseilRalenti, setConseilRalenti] = useState('');

    let pourcentageFonctionnement = 0;
    let pourcentageRalenti = 0;
    let temps_de_ralentis;
    let temps_de_travail;
    let tempsFonctionnement;

    // Verificar se as variáveis necessárias estão disponíveis e válidas
    const variablesIndefinidas = tempsTravail === null || tempsTravail === undefined || tempsTravail === 0;

    if (!variablesIndefinidas) {
        // Calcular tempos em horas e minutos
        temps_de_ralentis = Math.floor(tempsRalenti / 60) + "H" + (tempsRalenti % 60);
        temps_de_travail = Math.floor(tempsTravail / 60) + "H" + (tempsTravail % 60);

        // Calcular temps de fonctionnement
        tempsFonctionnement = tempsTravail - tempsRalenti;

        // Garantir que tempsTravail não seja zero para evitar divisão por zero
        if (tempsTravail > 0) {
            pourcentageFonctionnement = (tempsFonctionnement / tempsTravail) * 100;
            pourcentageRalenti = 100 -((tempsRalenti / tempsTravail) * 100);
        } else {
            pourcentageFonctionnement = 0;
            pourcentageRalenti = 0;
        }
    }

    const conseilsFonctionnement = {
        high: [
            "Vous assurez un fonctionnement fiable et efficace sur la période d'utilisation de la machine, poursuivez vos efforts !",
            "Votre engagement à maintenir les engins en excellent état démontre votre détermination à garantir leur durabilité et leur performance.",
            "Votre expertise et votre vigilance ont évité interruptions et retards, témoignant de votre engagement envers l'efficacité et la qualité du travail.",
            "En minimisant les périodes d'inactivité, vous maximisez l'efficacité du travail et réduisez la consommation de carburant et les émissions de CO2.",
            "Les conditions de fonctionnement de la machine sont optimales, continuez comme ça !",
            "Les performances de la machine sont bonnes, vous êtes sur la bonne voie !",
        ],
        medium: [
            "Veillez à ce que les axes des parties articulées soient suffisamment graissées",
            "Pensez à vérifier l'état du train de chenille",
            "Faîtes régulièrement le contrôle des pièces d'usure et la vérification du filtre à air",
            "Changer les filtres peut améliorer considérablement l'efficacité énergétique",
            "Limitez l'usage d'accessoires non indispensables, pouvant surcharger le moteur et augmenter la consommation de carburant.",
            "Programmez un nettoyage régulier de la machine pour éviter les dommages aux composants et les obstructions du système de refroidissement.",
        ],
        low: [
            "Maintenir une pression optimale des pneus peut améliorer considérablement l'efficacité énergétique",
            "Vérifiez si le mode de changement de vitesse est automatique ou manuel (le mode automatique est privilégié).",
            "Sélectionnez les outils de travail adaptés au type de travail",
            "Maintenez l'engin en bon état de fonctionnement",
            "Maintenez une vitesse constante lors du fonctionnement de la machine pour limiter les variations de consommation de carburant",
            "Optimisez les déplacements de la machine pour éviter les trajets inutiles. Réduisez les distances de déplacement et les mouvements au minimum.",
        ],
        veryLow: [
            "Effectuez régulièrement l'entretien des moteurs",
            "Sélectionnez le mode économique approprié si la machine en dispose, adapté à la tâche à accomplir (par exemple, mode P/E).",
            "Assurez un bon réglage des outils de travail",
            "Ne dépassez pas les limites de votre équipement, vous risquez d'endommager l'engin et de réduire ses performances",
            "Utilisez le rapport ou la vitesse appropriée pour le terrain et la tâche",
            "Priviliez le système de ralentissement pour stopper l'engin, réservant le freinage à l'immobilisation finale.",
        ],
    };

    const conseilsRalenti = {
        high: [
            "L'utilisation de la machine au ralenti respecte les normes d'éco-conduite",
            "L'empreinte carbone de la machine est en dessous de la moyenne, vous adoptez une bonne éco-conduite",
            "Votre conduite économise du carburant, réduit les coûts de maintenance et prolonge la durée de vie du matériel. Continuez ainsi !",
        ],
        medium: [
            "Veillez à supprimer les déplacements et les reprises inutiles de matériaux",
            "Favorisez lorsqu'il est disponible l'utilisation du système d'arrêt automatique \"Stop and Start\"",
            "Optimisez les temps de ralenti et respectez les procédures d'entretien pour prolonger la durée de vie des machines et réduire les coûts opérationnels",
        ],
        low: [
            "Planifiez les tâches efficacement pour réduire les temps d'attente et les déplacements, économisant ainsi du carburant.",
            "Choisissez le régime moteur et le rapport de transmission adapté à la tâche à réaliser",
            "Limitez l'usage d'accessoires supplémentaires pour éviter de surcharger le moteur et réduire la consommation de carburant.",
            ],
        veryLow: 
            [
            "Arrêtez les engins qui attendent pour charger ou décharger",
            "Limitez la mise en marche et le réchauffement du moteur trop longtemps",
            "Réduisez les temps de ralenti pour prolonger la durée de vie des moteurs et réduire la consommation de carburant.",
        ],
    };

    // Atualizar os conselhos quando as porcentagens mudarem
    useEffect(() => {
        if (pourcentageFonctionnement !== undefined && !isNaN(pourcentageFonctionnement)) {
            setConseilFonctionnement(getConseil(pourcentageFonctionnement, conseilsFonctionnement));
        }
    }, [pourcentageFonctionnement]);

    useEffect(() => {
        if (pourcentageRalenti !== undefined && !isNaN(pourcentageRalenti)) {
            setConseilRalenti(getConseil(pourcentageRalenti, conseilsRalenti));
        }
    }, [pourcentageRalenti]);

    // Função para atualizar os conselhos ao clicar no botão
    const handleShowMoreConseils = () => {
        if (pourcentageFonctionnement !== undefined) {
            setConseilFonctionnement(getConseil(pourcentageFonctionnement, conseilsFonctionnement));
        }
        if (pourcentageRalenti !== undefined) {
            setConseilRalenti(getConseil(pourcentageRalenti, conseilsRalenti));
        }
    };

    // Se as porcentagens não estiverem definidas, exibir a mensagem
    if (variablesIndefinidas || pourcentageFonctionnement === undefined || pourcentageRalenti === undefined) {
        return (
            <div className="tab-content">
                <button className="btnMsg">
                    Cet engin est à l'arrêt, pas de données pour cette période sélectionnée
                </button>
            </div>
        );
    }

    // Conteúdo normal se as porcentagens estiverem definidas
    return (
        <div className="tab-content">
            <div className="Conseil-main">
                <div className="Fonctionnement">
                    <div className='hStyle'>Fonctionnement</div>
                    <div className='rotate180'><BarProgression pourcentage={pourcentageFonctionnement} /></div>
                    <div className='space Scon'>{conseilFonctionnement}</div>
                </div>
                <div className="Ralenti">
                    <div className='hStyle'>Ralenti</div>
                    <BarProgression pourcentage={pourcentageRalenti} />
                    <div className='space'>
                        <img src={minuteur} className="minuteur" alt="image d'un minuteur" />
                        {temps_de_ralentis} sur {temps_de_travail} de travail
                    </div>
                    <div className='Scon'>{conseilRalenti}</div>
                </div>
                <button onClick={handleShowMoreConseils} className="conseil">
                    Affichez plus de conseils
                </button>
            </div>
        </div>
    );
};

// Função para obter um conseil aleatório com base na porcentagem
function getConseil(pourcentage, conseils) {
    if (pourcentage === undefined || isNaN(pourcentage)) {
        return ''; // Ou retorne uma mensagem padrão
    } else if (pourcentage >= 75) {
        return conseils.high[Math.floor(Math.random() * conseils.high.length)];
    } else if (pourcentage >= 50) {
        return conseils.medium[Math.floor(Math.random() * conseils.medium.length)];
    } else if (pourcentage >= 25) {
        return conseils.low[Math.floor(Math.random() * conseils.low.length)];
    } else {
        return conseils.veryLow[Math.floor(Math.random() * conseils.veryLow.length)];
    }
}

export default Conseil;
