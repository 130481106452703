import React, {useEffect, useState} from "react";
import "./Objectif.css"
import ImageSlider from "../../views/Graph/ImageSlider";
// import {annee, jour, mois, semaine} from "../../references/glossaire_frequence";
import {carburant2CO2} from "../../references/glossaire_carburant2CO2";


const Objectif = ({cumulLitresTravailObjectif, 
                    mediane_debitN, 
                    mediane_temps_fonctionnementN,  
                    mediane,
                    maxDebit, 
                    uniteTemps, 
                    carburant, 
                    carburantSelector, 
                    max_debit_ralentiN, 
                    max_debit_fonctionnementN, 
                    cumul_litres_fonctionnementN, 
                    cumul_litres_ralentiN, 
                    tempsRalenti, 
                    temps_fonctionnementN, 
                    tempsTravail,
                    mediane_debit_fonctionnement,
                    min_debit_ralenti
                 }) => {
    console.log("recuper les max debit relanti : ", max_debit_ralentiN)
    console.log("recuper max debit funcionenment: ", max_debit_fonctionnementN)
    console.log("recuper les cumul_litres_fonctionnementN : ", cumul_litres_fonctionnementN)
    console.log("recuper  cumul_litres_ralentiN: ", cumul_litres_ralentiN)

    console.log("recuper tempsRalenti: ", tempsRalenti)
    console.log("recuper les temps_fonctionnementN : ", temps_fonctionnementN)
    console.log("recuper  tempsTravail: ", tempsTravail)
    console.log("recuper  mediane_debit: ", mediane)
  
    console.log("recuper  cumul_litres_travail: ", cumulLitresTravailObjectif)

    console.log("recuper  mediane_debitN 1104: ", mediane_debitN)
    console.log("recuper  mediane_temps_fonctionnementN 1104: ", mediane_temps_fonctionnementN)



    console.log("recuper  mediane_debit_fonctionnement 1104: ", mediane_debit_fonctionnement)
    console.log("recuper  cumul_litres_travail: ", cumulLitresTravailObjectif)
    console.log("recuper  min_debit_ralenti: ", min_debit_ralenti)                

    const [budgetCO2, setBudgetCO2] = useState(null);
    useEffect(() => {
        // let tempsDeTravailleTheorique;
        // switch (uniteTemps) {
        //     case jour:
        //         tempsDeTravailleTheorique = 8;
        //         break;
        //     case semaine:
        //         tempsDeTravailleTheorique = 8 * 5;
        //         break;
        //     case mois:
        //         tempsDeTravailleTheorique = 8 * 21;
        //         break;
        //     case annee:
        //         tempsDeTravailleTheorique = 8 * 260;
        //         break;
        // }
    //     // setBudgetCO2(maxDebit * tempsDeTravailleTheorique * carburant2CO2["gazole"]) ancien formule
    //     setBudgetCO2((max_debit_fonctionnementN * temps_fonctionnementN * carburant2CO2["gazole"]) + ((max_debit_ralentiN * tempsRalenti * carburant2CO2["gazole"])*0.05))
    // }, [uniteTemps, maxDebit, max_debit_fonctionnementN, max_debit_ralentiN, mediane_temps_fonctionnement]);
    
//     setBudgetCO2(mediane_debitN * carburant2CO2[carburant] * mediane_temps_fonctionnementN)
// }, [mediane_debitN, mediane_temps_fonctionnementN, carburant]);
console.log("formule objetive :", mediane_debit_fonctionnement * carburant2CO2[carburant] * temps_fonctionnementN + min_debit_ralenti * carburant2CO2[carburant] * 0.05 * temps_fonctionnementN)
setBudgetCO2(mediane_debit_fonctionnement * carburant2CO2[carburant] * temps_fonctionnementN + min_debit_ralenti * carburant2CO2[carburant] * 0.05 * temps_fonctionnementN)
}, [mediane_debit_fonctionnement, temps_fonctionnementN, mediane_debitN, mediane_temps_fonctionnementN, carburant, min_debit_ralenti, cumulLitresTravailObjectif]);
    return <div className="tab-content">
        <div className={"Consommation-main"}>

            <span className={"texte-budget"}>Budget</span>

            <span className={"conso-budget-co2"}> 
                {/* {parseInt(budgetCO2)} Kg CO2 */}
                {budgetCO2 > 1000
                    ? `${(budgetCO2 / 1000).toFixed(1)} T CO2`
                    : `${parseInt(budgetCO2)} Kg CO2`}
            </span>

            <div className="input-group">
                <ImageSlider value={cumulLitresTravailObjectif * carburant2CO2[carburant]} maxValue={budgetCO2}/>
            </div>
            <span className={"text-consomme"}>Consommé</span>
            <div className={cumulLitresTravailObjectif * carburant2CO2[carburant] < budgetCO2
                ? "conso-consomme-co2"
                : "conso-consomme-co2 depassement"
            }>
               {/* {carburantSelector} {(cumulLitresTravailObjectif * carburant2CO2[carburant]).toFixed(2)} kg CO2 ancien formule */}
          {/* <div className="poliseMsg">{carburantSelector}</div>  */}
          <div className="poliseMsg">
            {/* {((cumul_litres_fonctionnementN * carburant2CO2[carburant]) + ((cumul_litres_ralentiN * carburant2CO2[carburant]) * 0.05)).toFixed(2)} kg CO2 */}
            {/* {(mediane_debitN * carburant2CO2[carburant] * temps_fonctionnementN).toFixed(2)} kg CO2 */}
            {/* {(cumulLitresTravailObjectif * carburant2CO2[carburant]).toFixed(2)} kg CO2 */}
            {cumulLitresTravailObjectif * carburant2CO2[carburant] > 1000
            ? `${(
                (cumulLitresTravailObjectif * carburant2CO2[carburant]) /
                1000
              ).toFixed(2)} T CO2`
            : `${(cumulLitresTravailObjectif * carburant2CO2[carburant]).toFixed(
                2
              )} Kg CO2`}
         </div>
        </div>
        </div>
    </div>
}

export default Objectif;