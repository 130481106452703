import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import UserLocation from './UserLocation';
import ValvesCluster from './ValvesCluster';
import './Map.css';
import Header from "../Header/Header";


const MapView = () => {
    const [userLocation, setUserLocation] = useState(null);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setUserLocation([latitude, longitude]);
            },
            (error) => {
                console.log(error);
            },
            {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0
            }
        );
    }, []);

    return (
        <div className="container">
            <Header titre={"Carte des électro-vannes"} />
            <MapContainer center={[48.8566, 2.3522]} zoom={13} style={{ height: '95vh', width: '100%' }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {userLocation && <UserLocation position={userLocation} />}
                <ValvesCluster />
            </MapContainer>
        </div>
    );
};

export default MapView;
