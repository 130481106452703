import React, {useEffect, useState} from 'react';
import './LoaderIcon.css'; // Assurez-vous que le fichier CSS est correctement lié

const LoaderIcon = () => {
    const [dots, setDots] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            setDots(prevDots => prevDots.length < 3 ? prevDots + '.' : '');
        }, 500); // Change les points toutes les 500 millisecondes

        return () => clearInterval(interval); // Nettoie l'intervalle lors du démontage du composant
    }, []);

    return (
        <span>
            {dots}
        </span>
    );
};

export default LoaderIcon;
