import React, {createContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

export const LanguageContext = createContext();
export const LanguageProvider = ({ children, navigate, currentLanguage }) => {
    const [language, setLanguage] = useState(currentLanguage || 'fr');
    const {lang} = useParams();
    const [translations, setTranslations] = useState({});

    useEffect(() => {
        if (lang && lang !== language) {
            setLanguage(lang);
            fetch(`/translations/${lang}/translation.json`)
                .then(response => response.json())
                .then(data => {
                    setTranslations(data);
                })
                .catch(error => console.error("Fetching translations failed", error));
        }
    }, [lang, language]);

    // Charger les traductions lors du changement de langue
    useEffect(() => {
        const loadTranslations = async () => {
            try {
                const apiUrl = `/translations/${language}/translation.json?${new Date().getTime()}`;

                const response = await fetch(apiUrl);
                const translations = await response.json();
                setTranslations(translations);
            } catch (error) {
                console.error("Erreur lors du chargement des traductions:", error);
            }
        };

        loadTranslations().then(r => r);
    }, [language]);

    // Fonction pour changer de langue
    const changeLanguage = (newLanguage) => {
        setLanguage(newLanguage);
        navigate(`/${newLanguage}/auth`);
    };


    return (
        <LanguageContext.Provider value={{language, setLanguage: changeLanguage, translations}}>
            {children}
        </LanguageContext.Provider>
    );
};
