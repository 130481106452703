import React, { useEffect } from 'react';
import { Marker, useMap } from 'react-leaflet';
import { createIcon } from './helpers'; // Assuming you moved createIcon to a helpers file

const UserLocation = ({ position }) => {
    const map = useMap();

    useEffect(() => {
        if (position) {
            map.flyTo(position, map.getZoom());
        }
    }, [position, map]);

    return <Marker position={position} icon={createIcon('U')}/>;
};

export default UserLocation;
