import React, { useEffect, useState } from "react";
import SemiCircleGauge from "../../views/Graph/SemiCircleGauge";
import "./Consommation.css";
import Histogram from "../../views/Graph/Histogram";
import { heure, jour, semaine, trimestre } from "../../references/glossaire_frequence";
import { carburant2CO2 } from "../../references/glossaire_carburant2CO2";
import PopConso from "../../ressources/images/popConso.png";
import {AiOutlineClose} from "react-icons/ai";

// Funções de conversão para timestamps
function convertUnixTimestampsToHours(timestamps) {
    return timestamps.map(timestamp => {
        const date = new Date(timestamp * 1000);
        const hours = date.getUTCHours().toString().padStart(2, '0');
        return hours + "h";
    });
}

function convertTimestampsToShortDayFormat(timestamps) {
    return timestamps.map(timestamp => {
        const date = new Date(timestamp * 1000);
        const formattedDate = date.toLocaleDateString('fr-FR', {
            weekday: 'short',
            day: 'numeric'
        });
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    });
}

function convertTimestampsToWeekNumber(timestamps) {
    return timestamps.map(timestamp => {
        const date = new Date(timestamp * 1000);
        const weekNumber = getWeekNumber(date);
        return `S${weekNumber}`;
    });
}

function getWeekNumber(d) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
}

function convertTimestampsToQuarterNumber(timestamps) {
    return timestamps.map(timestamp => {
        const weekNumber = getQuarterFromTimestamp(timestamp);
        return `T${weekNumber}`;
    });
}

function getQuarterFromTimestamp(utcTimestamp) {
    const date = new Date(utcTimestamp * 1000);
    const month = date.getMonth();
    return Math.ceil((month + 1) / 3);
}

const Consommation = ({
    datesConsommation,
    valuesConsommation,
    maxDebit,
    engineeringUnit,
    uniteTemps,
    carburant,
    carburantSelector,
    debit_general,
    min_debit_general,
    max_debit_general
}) => {
    const [ticks, setTicks] = useState([]);
    const litreParH = valuesConsommation[valuesConsommation.length - 1];
    const [c02ParH, setC02ParH] = useState(null);
    const [hasValidData, setHasValidData] = useState(true);
    const [adjustedValuesConsommation, setAdjustedValuesConsommation] = useState([]);

    // Novo estado para controlar a exibição da popup
    const [showPopup, setShowPopup] = useState(false);

    // Função para verificar se o valor é válido
    const isValid = (value) => value !== null && value !== undefined && value !== 0;

    useEffect(() => {
        if (!isValid(datesConsommation) || !isValid(valuesConsommation) || !isValid(maxDebit) || !isValid(litreParH)) {
            setHasValidData(false);
        } else {
            setHasValidData(true);
        }
    }, [datesConsommation, valuesConsommation, maxDebit, litreParH]);

    useEffect(() => {
        if (isValid(litreParH)) {
            setC02ParH(litreParH);
        }
    }, [litreParH]);

    // Atualiza os "ticks" com base no tipo de unidade de tempo
    useEffect(() => {
        let utsToTicks;
        switch (uniteTemps) {
            case heure:
                utsToTicks = convertUnixTimestampsToHours;
                break;
            case jour:
                utsToTicks = convertTimestampsToShortDayFormat;
                break;
            case semaine:
                utsToTicks = convertTimestampsToWeekNumber;
                break;
            case trimestre:
                utsToTicks = convertTimestampsToQuarterNumber;
                break;
            default:
                utsToTicks = v => v;
                break;
        }
        setTicks(utsToTicks(datesConsommation));
    }, [datesConsommation, uniteTemps]);

    // useEffect(() => {
    //     if (isValid(valuesConsommation)) {
    //         const adjustedValues = valuesConsommation.map(value => value);
    //         setAdjustedValuesConsommation(adjustedValues);
    //     } else {
    //         setAdjustedValuesConsommation(valuesConsommation);
    //     }
    // }, [valuesConsommation]);


    useEffect(() => {
        if (isValid(valuesConsommation) && carburant in carburant2CO2) {
        // if (isValid(valuesConsommation)) {
            const factor = carburant2CO2[carburant];
            const adjustedValues = valuesConsommation.map(value => value * factor);
            // const adjustedValues = valuesConsommation.map(value => value);
            setAdjustedValuesConsommation(adjustedValues);
        } else {
            setAdjustedValuesConsommation(valuesConsommation); // Mantém os valores originais se não houver fator
        }
    }, [valuesConsommation, carburant]);
    // Verifica se os dados são inválidos e renderiza o botão de aviso
    if (!hasValidData) {
        return (
            <button className="btnMsg" >
                Cet engin est à l'arrêt, pas de données pour cette période sélectionnée
            </button>
        );
    }

    // Função para lidar com o clique no botão
    const handleClick = () => {
        setShowPopup(true);
    };
    console.log(   "debit_general:", debit_general, "min_debit_general :", min_debit_general, "max_debit_general :",max_debit_general)
    return (
        <div className="tab-content">
            <div>
                <div className={"config-group"}>
                    <div className="input-group">
                        <span className="newcss">
                            <SemiCircleGauge
                                value={debit_general ? debit_general.toFixed(2) : 0}
                                unite={engineeringUnit}
                                max={max_debit_general}
                                min={min_debit_general}
                                color="#4194D0"
                                backgroundColor="#D9D9D9"
                                textColor="#000000"
                            />
                        </span>
                    </div>
                    <div className="input-group">
                        <Histogram
                            unite={engineeringUnit}
                            dates={ticks}
                            values={adjustedValuesConsommation}
                        />
                    </div>
                    <button className="conseil newconseil" onClick={handleClick}>
                        Afficher la légende
                    </button>

                    {/* Renderiza a popup se showPopup for true */}
                    {showPopup && (
                    <div className="backBluer">
                        <div className="popup nwspopup">
                            <div className="popup-content">

                                <span className="Nclose" onClick={() => setShowPopup(false)}> <AiOutlineClose className={"close-icon"}/></span>
                                <img src={PopConso} className={"npopup"} alt={"Legende"} />
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Consommation;
